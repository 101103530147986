import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      country: 'country'
    })
  },

  async mounted () {
    if (process.client) {
      const cart = JSON.parse(localStorage.getItem('cart'))
      const chat = JSON.parse(localStorage.getItem('chat'))
      const recent = JSON.parse(localStorage.getItem('recent'))
      const search = JSON.parse(localStorage.getItem('search'))
      const compare = JSON.parse(localStorage.getItem('compare'))

      const data = {
        cart: typeof cart === 'object' && cart !== null && 'id' in cart ? cart : null,
        chat: typeof chat === 'object' && chat !== null && 'id' in chat && 'key' in chat ? chat : null,
        recent: typeof recent === 'object' && recent !== null && 'id' in recent ? recent : null,
        search: typeof search === 'object' && search !== null && 'id' in search ? search : null,
        compare: typeof compare === 'object' && compare !== null && 'id' in compare ? compare : null
      }

      await this.$axios.post(`${this.$i18n.locale}/${this.country.iso_code}/deferred`, data, { progress: false })
        .then((res) => {
          if (res.data.cart) {
            this.$store.dispatch('cart/setCart', res.data.cart)
            localStorage.setItem('cart', JSON.stringify({
              id: res.data.cart.id
            }))
            this.$store.dispatch('cart/setCreditProducts', res.data.cartCreditProducts)
          } else {
            localStorage.removeItem('cart')
          }

          if (res.data.chat && res.data.chat.status.type.open) {
            this.$store.dispatch('chat/setChat', res.data.chat)
            this.$store.dispatch('support/setActive', false)
            this.$store.dispatch('chat/setActive', true)
          } else {
            localStorage.removeItem('chat')
          }

          if (res.data.recent) {
            this.$store.dispatch('recent/setRecent', res.data.recent)
            localStorage.setItem('recent', JSON.stringify({
              id: res.data.recent.id
            }))
          } else {
            localStorage.removeItem('recent')
          }

          if (res.data.search) {
            this.$store.dispatch('search/setSearch', res.data.search)
            localStorage.setItem('search', JSON.stringify({
              id: res.data.search.id
            }))
          } else {
            localStorage.removeItem('search')
          }

          if (res.data.compare) {
            this.$store.dispatch('compare/setCompare', res.data.compare)
            localStorage.setItem('compare', JSON.stringify({
              id: res.data.compare.id
            }))
          } else {
            localStorage.removeItem('compare')
          }
        })
        .catch((e) => {
          //
        })
        .finally(() => {
          this.$store.dispatch('cart/setCartLoading', false)
        })
    }
  }
}
