
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'

import Vue from 'vue'
import VueTelInput from 'vue-tel-input'
import vueTelInputAllCountries from '@/mixins/vue-tel-input-all-countries'

import UserSideMenu from '@/components/user/SideMenu'

Vue.use(VueTelInput)

export default {
  components: {
    UserSideMenu
  },

  mixins: [
    clickaway,
    vueTelInputAllCountries
  ],

  middleware: 'auth',

  async asyncData ({ params, $axios, app }) {
    const profile = await $axios.$get(`${app.i18n.locale}/${app.store.state.country.iso_code}/user/profile`)

    return {
      profile
    }
  },

  data () {
    return {
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.$t('pages.user.index.title'),
          to: '#',
          active: true
        }
      ],
      notification: {
        active: false,
        message: '',
        class: ''
      },
      form: {
        phone: {
          code: '',
          number: '',
          valid: true,
          country: undefined
        },
        gender: {
          isActiveDropdown: false
        },
        updating: false
      }
    }
  },

  computed: {
    ...mapGetters({
      country: 'country',
      countries: 'countries'
    }),

    name: {
      get () {
        return this.$auth.user.name
      },
      set (value) {
        const updatedUser = { ...this.$auth.user }
        updatedUser.name = value
        this.$auth.setUser(updatedUser)
      }
    },

    lastname: {
      get () {
        return this.$auth.user.lastname
      },
      set (value) {
        const updatedUser = { ...this.$auth.user }
        updatedUser.lastname = value
        this.$auth.setUser(updatedUser)
      }
    },

    patronymic: {
      get () {
        return this.$auth.user.patronymic
      },
      set (value) {
        const updatedUser = { ...this.$auth.user }
        updatedUser.patronymic = value
        this.$auth.setUser(updatedUser)
      }
    },

    email: {
      get () {
        return this.$auth.user.email
      },
      set (value) {
        const updatedUser = { ...this.$auth.user }
        updatedUser.email = value
        this.$auth.setUser(updatedUser)
      }
    },

    birthday: {
      get () {
        return this.$auth.user.birthday ? new Date(this.$auth.user.birthday).toISOString().split('T')[0] : null
      },
      set (value) {
        const updatedUser = { ...this.$auth.user }
        updatedUser.birthday = value
        this.$auth.setUser(updatedUser)
      }
    },

    phone: {
      get () {
        return this.$auth.user.phone
      },
      set (value) {
        const updatedUser = { ...this.$auth.user }
        updatedUser.phone = value
        this.$auth.setUser(updatedUser)
      }
    }
  },

  created () {
    this.form.phone.country = this.country

    import('bulma-calendar/dist/js/bulma-calendar.min.js')
      .then((bulmaCalendar) => {
        const calendar = bulmaCalendar.default.attach(this.$refs.calendarTrigger, { startDate: this.birthday })[0]
        calendar.on('select', datepicker => (this.birthday = new Date(datepicker.timeStamp) || null))
      })
  },

  mounted () {
    this.parsePhoneNumber(this.$auth.user.phone)
  },

  methods: {
    parsePhoneNumber (phone) {
      const country = phone
        ? this.countries.find(country => phone.startsWith(country.calling_code))
        : this.country

      this.form.phone.iso_code = country.iso_code
      this.form.phone.number = this.country ? phone.replace(country.calling_code, '') : ''
    },

    onPhoneInput (number, phone) {
      if (typeof phone.valid !== 'undefined' && phone.valid !== true) {
        this.form.phone.valid = false
      } else {
        this.form.phone.valid = true

        const updatedUser = { ...this.$auth.user }
        updatedUser.phone = phone.number
        this.$auth.setUser(updatedUser)
      }
    },

    formatter (date) {
      return date.toLocaleDateString()
    },

    hideGenderDropDown () {
      this.form.gender.isActiveDropdown = false
    },

    toggleGenderSelect () {
      this.form.gender.isActiveDropdown = !this.form.gender.isActiveDropdown
    },

    changeGender (gender) {
      const updatedUser = { ...this.$auth.user }
      updatedUser.gender = gender
      this.$auth.setUser(updatedUser)

      this.hideGenderDropDown()
    },

    update () {
      this.form.updating = true

      this.$axios.$put(`${this.$i18n.locale}/${this.country.iso_code}/user/profile`, this.$auth.user)
        .then((res) => {
          this.notification.active = true
          this.notification.message = res.message
          this.notification.class = 'is-success'
        })
        .finally(() => (this.form.updating = false))
    }
  },

  head () {
    return {
      title: this.$t('pages.user.index.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.user.index.description')
        }
      ]
    }
  }
}
