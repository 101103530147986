
import { mapGetters } from 'vuex'

import user from '@/mixins/checkout/user.js'
import vueTelInputAllCountries from '@/mixins/vue-tel-input-all-countries'

export default {
  mixins: [
    user,
    vueTelInputAllCountries
  ],

  props: {
    tabs: {
      required: true,
      default () {
        return []
      },
      type: Array
    }
  },

  data () {
    return {
      user: {
        phone: {
          focus: false,
          country: undefined
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      authModal: 'modal/auth',
      checkout: 'checkout/checkout',
      countries: 'countries',
      country: 'country'
    }),

    userNameValue: {
      get () {
        return this.checkout.form.user.name.value
      },
      set (value) {
        this.$store.dispatch('checkout/setUserNameValue', value)
      }
    },

    userLastnameValue: {
      get () {
        return this.checkout.form.user.lastname.value
      },
      set (value) {
        this.$store.dispatch('checkout/setUserLastnameValue', value)
      }
    },

    userPatronymicValue: {
      get () {
        return this.checkout.form.user.patronymic.value
      },
      set (value) {
        this.$store.dispatch('checkout/setUserPatronymicValue', value)
      }
    },

    userPhoneModel: {
      get () {
        return this.checkout.form.user.phone.model
      },
      set (value) {
        this.$store.dispatch('checkout/setUserPhoneModel', value)
      }
    },

    userEmailValue: {
      get () {
        return this.checkout.form.user.email.value
      },
      set (value) {
        this.$store.dispatch('checkout/setUserEmailValue', value)
      }
    }
  },

  watch: {
    '$auth.loggedIn': {
      handler (val) {
        if (val) {
          this.$store.dispatch('checkout/setUserNameValue', this.$auth.user.name)
          this.$store.dispatch('checkout/setUserLastnameValue', this.$auth.user.lastname)
          this.$store.dispatch('checkout/setUserPatronymicValue', this.$auth.user.patronymic)
          this.$store.dispatch('checkout/setUserPhone', this.$auth.user.phone)
          this.$store.dispatch('checkout/setUserPhoneModel', this.$auth.user.phone.replace(this.country.calling_code, ''))
          this.$store.dispatch('checkout/setUserEmailValue', this.$auth.user.email)

          this.validateUserName() // Autofocus issue
        }
      },
      immediate: true,
      deep: true
    }
  },

  created () {
    this.user.phone.country = this.country
  },

  methods: {
    validateUserNameOnKeydown () {
      if (this.checkout.form.user.name.value.length === 0) {
        this.$store.dispatch('checkout/validateUserName', '')
      }
    },

    validateUserNameOnKeyup () {
      if (this.checkout.form.user.name.error === '') {
        return
      }

      this.validateUserName()
    },

    validateUserName () {
      if (this.checkout.form.user.name.value.length >= 1) {
        this.$store.dispatch('checkout/validateUserName', '')
      } else if (this.checkout.form.user.name.value.length >= 1 && this.checkout.form.user.name.value.length < 3) {
        this.$store.dispatch('checkout/validateUserName', this.$t('pages.checkout.user.name.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('checkout/validateUserName', this.$t('pages.checkout.user.name.errors.required'))
      }
    },

    validateUserEmailOnKeyup () {
      if (this.checkout.form.user.email.value.length === 0) {
        this.$store.dispatch('checkout/validateUserEmail', '')
      }

      if (this.checkout.form.user.email.error === '') {
        return
      }

      this.validateUserEmail()
    },

    onUserPhoneInput (number, phone) {
      this.$store.dispatch('checkout/setUserPhone', phone.number)

      if (typeof phone.valid === 'undefined' || phone.valid === true) {
        this.$store.dispatch('checkout/setUserPhoneError', '')
      } else {
        this.$store.dispatch('checkout/setUserPhoneError', this.$t('pages.checkout.user.phone.errors.invalid'))
      }
    },

    validateUserEmail () {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(this.checkout.form.user.email.value) || this.checkout.form.user.email.value === '') {
        this.$store.dispatch('checkout/validateUserEmail', '')
      } else {
        this.$store.dispatch('checkout/validateUserEmail', this.$t('pages.checkout.user.email.errors.invalid'))
      }
    }
  }
}
