
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'
import { defineAsyncComponent } from '@vue/composition-api'

import ChatStartForm from '@/components/chat/StartForm'
import ChatTicketForm from '@/components/chat/TicketForm'
import SupportContact from '@/components/support/Contact'

export default {
  components: {
    ChatStartForm,
    ChatTicketForm,
    SupportContact,
    LazyChat: defineAsyncComponent(() => import('@/components/Chat'))
  },

  mixins: [
    clickaway
  ],

  data () {
    return {
      isActive: false,
      isMounted: false,
      swiperOption: {
        // simulateTouch: false,
        // lazy: true,
        // loop: true,
        slidesPerView: 1,
        autoplay: {
          delay: 1000
        },
        allowTouchMove: false,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        on: {
          slideChange () {
            //
          },
          tap () {
            //
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      support: 'support/support',
      callback: 'modal/callback',
      email: 'modal/email',
      chat: 'chat/chat',
      settings: 'settings'
    }),

    operating () {
      const schedule = this.support.schedule.days
      const timeFormat = 'hh:mm'
      let operating

      if (Array.isArray(schedule)) {
        const moment = this.$moment().locale(this.$i18n.locale)
        const weekday = moment.isoWeekday()
        const open = this.$moment(schedule[weekday - 1].open, timeFormat)
        const close = this.$moment(schedule[weekday - 1].close, timeFormat)

        if (moment.isBefore(open) && schedule[weekday - 1].open && schedule[weekday - 1].close) {
          operating = {
            active: false,
            title: this.$t('support.today.notYet.title'),
            subtitle: this.$t('support.today.notYet.subtitle', {
              open: schedule[weekday - 1].open,
              close: schedule[weekday - 1].close
            }),
            class: 'has-text-warning'
          }
        } else if (moment.isBetween(open, close) && schedule[weekday - 1].open && schedule[weekday - 1].close) {
          operating = {
            active: true,
            title: this.$t('support.today.operating.title'),
            subtitle: this.$t('support.today.operating.subtitle', {
              close: schedule[weekday - 1].close
            }),
            class: 'has-text-success'
          }
        } else {
          for (let i = 1; i <= 7; i++) {
            let nextDay = weekday + i

            if (nextDay > 7) {
              nextDay -= 7
            }

            if (nextDay in schedule && schedule[nextDay - 1].open && schedule[nextDay - 1].close) {
              let title = ''

              if (i === 1 && (!schedule[weekday - 1].open || !schedule[weekday - 1].close)) {
                title += `${this.$t('support.today.dayOff.title')}<br>`
              } else if (i > 1) {
                title += `${this.$t('support.daysOff.title')}<br>`
              }

              if (i === 1) {
                title += this.$t('support.tomorrow.title')
              } else {
                title += this.$t('support.nextDay.title', {
                  weekday: this.$moment().isoWeekday(nextDay).format('dd').toUpperCase()
                })
              }

              operating = {
                active: false,
                title,
                subtitle: this.$t('support.nextDay.subtitle', {
                  open: schedule[nextDay - 1].open,
                  close: schedule[nextDay - 1].close
                }),
                class: 'has-text-warning'
              }

              break
            }
          }

          if (operating === undefined) {
            operating = {
              active: false,
              title: this.$t('support.today.dayOff.title'),
              subtitle: '',
              class: 'has-text-grey'
            }
          }
        }
      } else if (schedule === '') {
        operating = {
          active: true,
          title: this.$t('support.allDay.title'),
          subtitle: this.$t('support.allDay.subtitle'),
          class: 'has-text-grey'
        }
      }

      return operating
    },

    hasChatWidget () {
      return this.support.contacts.filter(contact => contact.group === 'chat_widget').length > 0
    }
  },

  watch: {
    $route (to, from) {
      this.pageHasFixedButton()
      this.$store.dispatch('support/setProductFixedPriceButton', false)
    }
  },

  async created () {
    await this.pageHasFixedButton()
  },

  mounted () {
    this.$nextTick(() => {
      this.isMounted = true
    })

    if (this.$refs.callback) {
      this.$refs.callback.addEventListener('click', (event) => {
        this.$store.dispatch('modal/setCallbackActive', true)
      }, { passive: true })
    }
  },

  methods: {
    toggleDropDown () {
      this.isActive = !this.isActive
    },

    hideSupportContactDropdown (e) {
      e.stopPropagation()

      this.isActive = false
    },

    async pageHasFixedButton () {
      await this.$store.dispatch(
        'support/setProductFixedPriceButton',
        this.$route.name.startsWith('slug___') &&
          this.support.productFixedPriceButton &&
          this.$route.query.tab !== 'stores'
      )
    },

    clickContact (contact) {
      if (contact === 'callback') {
        this.$store.dispatch('modal/setCallbackActive', true)
      } else if (contact === 'chat_widget') {
        this.$store.dispatch('support/setActive', false)
        this.$store.dispatch('chat/setActive', true)
      } else if (contact === 'email' && this.email.tabs.length) {
        this.$store.dispatch('modal/setEmailTab', this.email.tabs[0])
        this.$store.dispatch('modal/setEmailActive', true)
      }
    }
  }
}
