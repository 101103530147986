
import { mapGetters } from 'vuex'

import filters from '@/mixins/filters.js'
import gtm from '@/mixins/gtm.js'

import ProductCard from '@/components/product/Card'
import FiltersLoading from '@/components/filters/Loading'
import LoadMore from '@/components/LoadMore'

export default {
  components: {
    ProductCard,
    FiltersLoading,
    LoadMore
  },

  mixins: [
    filters,
    gtm
  ],

  props: {
    promo: {
      type: Object,
      default () {
        return {}
      },
      required: true
    }
  },

  data () {
    return {
      path: {
        name: 'slug',
        params: {
          slug: this.$route.params.slug
        }
      },
      pathWithParams: {
        name: 'slug-params',
        params: {
          slug: this.$route.params.slug,
          params: undefined
        }
      },
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.promo.title,
          to: '#',
          active: true
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      country: 'country',
      currency: 'currency'
    })
  },

  created () {
    this.pushGtmViewItemListEvent(this.promo.products, this.promo.title, this.promo.id)

    if (this.$route.params.params) {
      this.params = this.$route.params.params

      this.params.split('_').forEach((param) => {
        const values = param.split('--')
        const filterSlug = values.shift()

        this.initializeConditionStatusChosen(filterSlug, values, this.promo.statuses.condition)
        this.initializeStockStatusChosen(filterSlug, values, this.promo.statuses.stock)
        this.initializeCategoryChosen(filterSlug, values, Object.values(this.promo.categories).flat())
        this.initializePricesChosen(filterSlug, values)
        this.initializeSortChosen(filterSlug, values)
      })
    }

    this.initializeSortDefault()

    this.setInitialPage(this.promo.pages)
  },

  methods: {
    async loadMoreProducts () {
      if (this.page >= this.promo.pages) {
        return
      }

      this.page += 1
      this.loading = true

      const badges = `badges--${this.promo.badges.map(b => b.slug).join('--')}` + (this.params.length ? '_' : '')

      await this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/products/${badges}${this.params}?page=${this.page}`)
        .then((res) => {
          this.reachedLastPage = this.page === this.promo.pages
          this.promo.products = [...this.promo.products, ...res.data]

          this.pushGtmViewItemListEvent(res.data, this.promo.title, this.promo.id)

          // history.replaceState({}, null, `${window.location.origin}${window.location.pathname}?page=${this.page}`)
        })
        .finally(() => {
          this.loading = false
        })
    },

    getPath (params) {
      if (params) {
        return this.localePath({
          name: 'slug-params',
          params: {
            slug: this.$route.params.slug,
            params
          }
        })
      } else {
        return this.localePath({
          name: 'slug',
          params: {
            slug: this.$route.params.slug
          }
        })
      }
    }
  },

  head () {
    return {
      title: this.promo.page.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.search.description')
        }
      ]
    }
  }
}
