import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      country: 'country'
    }),

    endpoint () {
      return `${this.$i18n.locale}/${this.country.iso_code}/location`
    }
  },

  methods: {
    async getLocation () {
      await this.$axios.$get(this.endpoint, { progress: false })
        .then((location) => {
          this.$store.commit('SET_LOCATION', location.data)
        })
    },

    async getCurrentPosition () {
      if (this.$geolocation.checkSupport()) {
        await this.$geolocation.getCurrentPosition()
          .then((location) => {
            const query = {}

            if (location.coords.latitude && location.coords.longitude) {
              query.latitude = location.coords.latitude
              query.longitude = location.coords.longitude
            }

            this.$axios.$get(`${this.endpoint}${Object.keys(query).length > 0 ? `?${new URLSearchParams(query).toString()}` : ''}`, { progress: false })
              .then((location) => {
                this.$store.commit('SET_LOCATION', location.data)
              })
          })
      }
    },

    async getDeliveryOptions (product, city) {
      this.$store.commit('SET_LOCAL_DELIVERY_OPTIONS', {})

      await this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/product/${product.id}/cities/${city.id}`, { progress: false })
        .then((location) => {
          this.$store.commit('SET_LOCAL_DELIVERY_OPTIONS', location.data.deliveryOptions)
          this.$store.commit('SET_LOCAL_PAYMENT_OPTIONS', location.data.paymentOptions)
        })
    },

    setCity (city) {
      this.$store.commit('SET_CITY', city)
    }
  }
}
