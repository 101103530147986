
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      show: false
    }
  },

  computed: {
    ...mapGetters({
      checkout: 'checkout/checkout'
    }),

    noteBody: {
      get () {
        return this.checkout.form.note.body
      },
      set (value) {
        this.$store.dispatch('checkout/setNoteBody', value)
      }
    },

    callback: {
      get () {
        return this.checkout.form.callback
      },
      set (value) {
        this.$store.dispatch('checkout/setCallback', value)
      }
    }
  },

  methods: {
    toggleFormNoteVisibility () {
      this.show = !this.show

      if (this.show) {
        this.$nextTick(() => {
          this.$refs.note.$el.focus()
        })
      }
    }
  }
}
