
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from '@vue/composition-api'

export default {
  components: {
    MenusCatalogMenuDropdown: defineAsyncComponent(() => import('@/components/menus/CatalogMenuDropdown'))
  },

  data: () => ({
    isActive: false
  }),

  computed: {
    ...mapGetters({
      categories: 'categories',
      settings: 'settings'
    })
  },

  watch: {
    $route: {
      handler (val) {
        this.setExpanded()
      },
      immediate: true
    }
  },

  methods: {
    setExpanded () {
      this.isActive = [this.localePath('index'), `${this.localePath('index')}/`].includes(this.$route.path) && this.settings.index.show_catalog
    },

    catalogMouseOver () {
      this.isActive = true
    },

    catalogMouseleave () {
      this.setExpanded()
    }
  }
}
