
import { mapGetters } from 'vuex'

import operating from '@/mixins/callcenter/operating.js'

import SupportContact from '@/components/support/Contact'

export default {
  components: {
    SupportContact
  },

  mixins: [
    operating
  ],

  data () {
    return {
      isActive: false
    }
  },

  computed: {
    ...mapGetters({
      callcenter: 'callcenter',
      callback: 'modal/callback'
    })
  }
}
