
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from '@vue/composition-api'

// import cart from '@/mixins/cart.js'
// import chat from '@/mixins/chat.js'
import deferred from '@/mixins/deferred.js'
// import compare from '@/mixins/compare.js'
// import recent from '@/mixins/recent.js'
import referrer from '@/mixins/referrer.js'
import theme from '@/mixins/theme.js'

import Notification from '@/components/Notification'
import HeaderNavTop from '@/components/header/NavTop'
import HeaderNavBottom from '@/components/header/NavBottom'

export default {
  components: {
    Notification,
    HeaderNavTop,
    HeaderNavBottom,
    LazyFooterNav: defineAsyncComponent(() => import('@/components/footer/Nav')),
    LazyCookie: defineAsyncComponent(() => import('@/components/Cookie')),
    LazySupport: defineAsyncComponent(() => import('@/components/Support')),
    LazyModalsAuthModal: defineAsyncComponent(() => import('@/components/modals/AuthModal')),
    LazyModalsCartModal: defineAsyncComponent(() => import('@/components/modals/CartModal')),
    LazyModalsNotifyAboutAvailabilityModal: defineAsyncComponent(() => import('@/components/modals/NotifyAboutAvailabilityModal')),
    LazyModalsPreorderModal: defineAsyncComponent(() => import('@/components/modals/PreorderModal')),
    LazyModalsCallbackModal: defineAsyncComponent(() => import('@/components/modals/CallbackModal')),
    LazyModalsEmailModal: defineAsyncComponent(() => import('@/components/modals/EmailModal')),
    LazyModalsInfoModal: defineAsyncComponent(() => import('@/components/modals/InfoModal'))
  },

  mixins: [
    // cart,
    // chat,
    deferred,
    // compare,
    // recent,
    referrer,
    theme
  ],

  data: () => ({
    lastScrollTop: 0,
    searchIsHiddenMobile: true
  }),

  computed: {
    ...mapGetters({
      authModal: 'modal/auth',
      cart: 'cart/cart',
      cartModal: 'cart/modal',
      chat: 'chat/chat',
      info: 'modal/info',
      preorder: 'modal/preorder',
      notifyAboutProductAvailability: 'modal/notifyAboutProductAvailability',
      callback: 'modal/callback',
      email: 'modal/email',
      notification: 'notification',
      sticky: 'sticky',
      schemaOrg: 'schema.org/schemaOrg',
      support: 'support/support'
    })
  },

  mounted () {
    const html = document.documentElement
    html.style.overflow = null
    this.setReferrer()
    window.addEventListener('scroll', this.onScroll, { passive: true })
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    onScroll (e) {
      const st = window.scrollY || document.documentElement.scrollTop

      if (['mobileSmall', 'mobile'].includes(this.$mq) &&
        (
          this.authModal.active ||
          (this.cartModal.active && this.cart.items.length) ||
          this.notifyAboutProductAvailability.active ||
          this.preorder.active ||
          this.callback.active ||
          this.email.active ||
          this.info.active ||
          this.chat.active
        )
      ) {
        return
      }

      if (st > this.lastScrollTop) {
        this.$store.dispatch('setSticky', false)
      } else if (st < this.lastScrollTop) {
        this.$store.dispatch('setSticky', true)
      }

      const productsTab = document.getElementById('product-tabs')

      if (productsTab) {
        productsTab.style.top = !this.sticky ? 0 : ''
      }

      this.lastScrollTop = st <= 0 ? 0 : st
    },

    toggleCartModal () {
      this.$store.dispatch('cart/setCartModalActive', !this.cartModal.active)
    },

    togglePreorderModal () {
      this.$store.dispatch('modal/togglePreorderModal', !this.preorder.active)
      this.$store.dispatch('modal/setPreorderId', null)
      this.$store.dispatch('modal/setPreorderTitle', '')
      this.$store.dispatch('modal/setPreorderImage', {
        lazy: '',
        src: '',
        alt: ''
      })
    },

    toggleNotifyAboutAvailabilityModal () {
      this.$store.dispatch('modal/toggleNotifyAboutProductAvailabilityModal', !this.notifyAboutProductAvailability.active)
      this.$store.dispatch('modal/setNotifyAboutProductAvailabilityId', null)
      this.$store.dispatch('modal/setNotifyAboutProductAvailabilityTitle', '')
      this.$store.dispatch('modal/setNotifyAboutProductAvailabilityImage', {
        lazy: '',
        src: '',
        alt: ''
      })
    },

    toggleAuthModal () {
      this.$store.dispatch('modal/setAuthActive', false)
    },

    toggleCallbackModal () {
      this.$store.dispatch('modal/setCallbackActive', false)
    },

    toggleEmailModal () {
      this.$store.dispatch('modal/setEmailActive', false)
      this.$store.dispatch('modal/setEmailTab', null)
    },

    toggleInfoModal () {
      this.$store.dispatch('modal/setInfoModalActive', !this.info.active)
      this.$store.dispatch('modal/setInfoModalTitle', '')
      this.$store.dispatch('modal/setInfoModalDescription', '')
      this.$store.dispatch('modal/setInfoModalPage', { slug: '' })
    }
  },

  head () {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    i18nHead.link = i18nHead.link.filter(e => e.rel !== 'canonical')

    i18nHead.script = [
      {
        type: 'application/ld+json',
        json: this.schemaOrg.store,
        body: true
      }
    ]

    i18nHead.meta = [
      {
        hid: 'theme-color',
        name: 'theme-color',
        content: this.themeColor
      }
    ]

    return i18nHead
  }
}
