
export default {
  props: {
    last: {
      type: Number,
      default () {
        return 1
      },
      required: true
    },
    current: {
      type: Number,
      default () {
        return 1
      },
      required: true
    }
  },

  data () {
    return {
      first: 1
    }
  },

  computed: {
    pagination () {
      const pagination = {
        first: {
          isActive: this.current === this.first,
          component: this.current === this.first ? 'span' : 'button'
        },
        last: {
          isActive: this.current === this.last,
          component: this.current === this.last ? 'span' : 'button'
        }
      }

      if (this.current > this.first) {
        pagination.previous = {
          page: this.current - 1,
          isActive: false,
          component: 'button'
        }
      }

      if (this.current < this.last) {
        pagination.next = {
          page: this.current + 1,
          isActive: false,
          component: 'button'
        }
      }

      return pagination
    }
  }
}
