
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'

import ButtonsAuth from '@/components/buttons/Auth'
import ButtonsCompare from '@/components/buttons/Compare'
import ButtonsCart from '@/components/buttons/Cart'
import ButtonsSearch from '@/components/buttons/Search'
import MenusCatalogMenu from '@/components/menus/CatalogMenu'
import MenusMobileMenu from '@/components/menus/MobileMenu'

export default {
  components: {
    ButtonsAuth,
    ButtonsCompare,
    ButtonsCart,
    ButtonsSearch,
    MenusCatalogMenu,
    MenusMobileMenu
  },

  mixins: [
    clickaway
  ],

  data: () => ({
    searchIsHiddenMobile: true
  }),

  computed: {
    ...mapGetters({
      notification: 'notification'
    })
  },

  methods: {
    toggleSearch () {
      const html = document.documentElement
      html.style.overflow = null

      this.searchIsHiddenMobile = !this.searchIsHiddenMobile

      this.$nextTick(() => {
        this.$refs.search.$el.getElementsByClassName('input')[0].focus()
      })
    },

    away () {
      if (['mobileSmall', 'mobile'].includes(this.$mq)) {
        this.searchIsHiddenMobile = true
      }
    },

    isIndex () {
      return this.$route.path === this.localePath('index')
    }
  }
}
