
import { mapGetters } from 'vuex'

import { mixin as clickaway } from 'vue-clickaway'

import Callcenter from '@/components/Callcenter'
import MenusHeaderMenu from '@/components/menus/HeaderMenu'
// import Location from '@/components/Location'

export default {
  components: {
    Callcenter,
    MenusHeaderMenu
    // Location
  },

  mixins: [
    clickaway
  ],

  computed: {
    ...mapGetters({
      location: 'location',
      callcenter: 'callcenter'
    })
  }
}
