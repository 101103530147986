
import { mapGetters } from 'vuex'

const __default__ = {
  computed: {
    ...mapGetters({
      currency: 'currency',
      notification: 'notification'
    }),

    link () {
      return {
        name: 'slug',
        params: {
          slug: this.notification.bannerable.page.slug
        }
      }
    },

    closeStyles () {
      const data = {}

      if (!this.notification.show_title && !this.notification.show_price) {
        data.style = {
          position: 'absolute',
          right: '1rem'
        }
      }

      return data
    },

    closeContainerStyles () {
      const data = {}

      if (!this.notification.show_title && !this.notification.show_price) {
        data.style = {
          position: 'unset'
        }
      }

      return data
    },

    // background () {
    //   if (this.$mq === 'fullhd' && this.$mq in this.notification.backgrounds) {
    //     return this.notification.backgrounds.fullhd
    //   } else if (this.$mq === 'widescreen' && this.$mq in this.notification.backgrounds) {
    //     return this.notification.backgrounds.widescreen
    //   } else if (this.$mq === 'desktop' && this.$mq in this.notification.backgrounds) {
    //     return this.notification.backgrounds.desktop
    //   } else if (this.$mq === 'tablet' && this.$mq in this.notification.backgrounds) {
    //     return this.notification.backgrounds.tablet
    //   } else if (this.$mq === 'mobile' && this.$mq in this.notification.backgrounds) {
    //     return this.notification.backgrounds.mobile
    //   } else {
    //     return this.notification.backgrounds.mobileSmall
    //   }
    // },

    style () {
      return {
        '--mobile-small': this.notification.backgrounds.mobileSmall.style.background,
        '--mobile': this.notification.backgrounds.mobile.style.background,
        '--tablet': this.notification.backgrounds.tablet.style.background,
        '--desktop': this.notification.backgrounds.desktop.style.background,
        '--widescreen': this.notification.backgrounds.widescreen.style.background,
        '--fullhd': this.notification.backgrounds.fullhd.style.background
      }
    }
  },

  methods: {
    close (e) {
      e.preventDefault()

      this.$store.dispatch('setNotificationActivity', false)
    }
  }
}

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "7aa28905": (_vm.notification.color.value)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__