
import { mapGetters } from 'vuex'

import UserSideMenu from '@/components/user/SideMenu'
import OrderDelivery from '@/components/order/Delivery'
import Pagination from '@/components/Pagination'

import theme from '@/mixins/theme.js'

export default {
  components: {
    UserSideMenu,
    OrderDelivery,
    Pagination
  },

  mixins: [
    theme
  ],

  middleware: 'auth',

  async asyncData ({ $axios, app }) {
    const response = await $axios.$get(`${app.i18n.locale}/${app.store.state.country.iso_code}/user/orders`, {
      params: {
        sortBy: 'created_at',
        sortOrder: 'desc',
        itemsPerPage: 10,
        page: 1
      }
    })

    return {
      orders: response.data,
      meta: response.meta
    }
  },

  data () {
    return {
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.$t('pages.user.index.title'),
          to: this.localePath('user'),
          active: false
        },
        {
          title: this.$t('pages.user.orders.title'),
          to: '#',
          active: true
        }
      ],
      page: 1,
      itemsPerPage: 10,
      loading: false,
      sort: {
        by: 'created_at',
        order: 'desc'
      },
      orders: [],
      meta: null
    }
  },

  computed: {
    ...mapGetters({
      country: 'country',
      currency: 'currency'
    })
  },

  methods: {
    loadAsyncData () {
      this.loading = true

      this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/user/orders`, {
        params: {
          sortBy: this.sort.by,
          sortOrder: this.sort.order,
          itemsPerPage: this.itemsPerPage,
          page: this.page
        }
      })
        .then((res) => {
          this.orders = res.data
          this.meta = res.meta
          window.scrollTo({ top: 0, behavior: 'smooth' })
        })
        .catch((e) => {
          this.orders = []
          this.meta.total = 0
        })
        .finally(() => (this.loading = false))
    },

    onPageChange (page) {
      this.page = page
      this.loadAsyncData()
    },

    onSort (field, order) {
      this.sort.by = field
      this.sort.order = order
      this.loadAsyncData()
    },

    total (order) {
      return order.deliveries.flatMap(d => d.items).reduce((total, item) => {
        return total + item.quantity * (item.price + item.warranties.reduce((total, w) => total + w.price, 0) + item.addons.reduce((total, a) => total + a.price, 0))
      }, 0)
    },

    totalDelivery (order) {
      return order.deliveries.reduce((total, delivery) => {
        return total + delivery.price
      }, 0)
    },

    totalDiscount (order) {
      return order.deliveries.flatMap(d => d.items).reduce((total, item) => {
        const price = item.store.price.current + item.warranties.reduce((total, item) => total + item.price, 0) + item.addons.reduce((total, item) => total + item.price, 0)

        let discount = 0

        if (item.coupon && item.coupon.discount.type.type === 'percent') {
          discount = price * item.coupon.discount.percent / 100
        } else if (item.coupon && item.coupon.discount.type.type === 'amount') {
          discount = item.coupon.discount.amount.amount
        }

        return total + item.quantity * discount
      }, 0)
    },

    totalCommission (order) {
      if (order.payments[0].method.title == null) {
        return 0
      } else if (order.payments[0].method.commission.type === 'percent') {
        return Math.ceil((this.total(order) + this.totalDelivery(order) - this.totalDiscount(order)) * order.payments[0].method.commission.value) / 100
      } else if (order.payments[0].method.commission.type === 'amount') {
        return order.payments[0].method.commission.value
      } else {
        return 0
      }
    },

    grandTotal (order) {
      return this.total(order) + this.totalDelivery(order) + this.totalCommission(order) - this.totalDiscount(order)
    }
  },

  head () {
    return {
      title: this.$t('pages.user.orders.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.user.orders.description')
        }
      ]
    }
  }
}
