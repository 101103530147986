
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      scroll: {
        left: 0,
        touch: {
          x: 0,
          y: 0
        },
        translate: 0,
        finished: true
      },
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.$t('pages.compare.title'),
          to: '#',
          active: true
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      compare: 'compare/compare',
      currency: 'currency',
      logo: 'logo'
    }),

    perPage () {
      if (['mobileSmall', 'mobile'].includes(this.$mq)) {
        return this.columns >= 2 ? 2 : this.columns
      } else if (['tablet'].includes(this.$mq)) {
        return this.columns >= 3 ? 3 : this.columns
      } else if (['desktop'].includes(this.$mq)) {
        return this.columns >= 4 ? 4 : this.columns
      } else {
        return this.columns >= 5 ? 5 : this.columns
      }
    },

    columns () {
      let length = this.compare.items.length

      if (!['mobileSmall', 'mobile'].includes(this.$mq)) {
        length++
      }

      return length
    },

    tdWidth () {
      return `width: ${100 / (this.columns)}%`
    },

    tableWidth () {
      return `width: ${100 / (this.perPage) * this.columns}%`
    },

    pricesDiffer () {
      return [...new Set(this.compare.items.map(item => item.price.current))].length > 1
    }
  },

  methods: {
    remove (item) {
      this.$store.dispatch('compare/removeCompare', { item })
    },

    characteristicValuesDiffer (characteristic) {
      return (
        characteristic.field === 'select' &&
        this.compare.items.map(item => (item.characteristics.find(c => c.id === characteristic.id) !== undefined ? item.characteristics.find(c => c.id === characteristic.id).values : [])).length > 0 &&
        this.compare.items.map(item => (item.characteristics.find(c => c.id === characteristic.id) !== undefined ? item.characteristics.find(c => c.id === characteristic.id).values : []).map(v => v.id).sort().toString()).filter((value, index, array) => array.indexOf(value) === index).length > 1
      ) ||
      (
        characteristic.field === 'input' &&
        [...new Set(this.compare.items.map(item => item.characteristics.find(c => c.id === characteristic.id) !== undefined ? item.characteristics.find(c => c.id === characteristic.id).value : ''))].length > 1
      )
    },

    wheelHorizontally (e) {
      if (Math.abs(e.deltaY) > Math.abs(e.deltaX)) {
        return
      }

      e.preventDefault()
      e.stopPropagation()

      this.setTranslate3d(e.deltaX)
    },

    touchstartHorizontally (e) {
      this.scroll.touch.x = e.touches[0].clientX
      this.scroll.touch.y = e.touches[0].clientY
    },

    touchmoveHorizontally (e) {
      const deltaX = this.scroll.touch.x - e.touches[0].clientX
      const deltaY = this.scroll.touch.y - e.touches[0].clientY

      if (Math.abs(deltaY) > Math.abs(deltaX)) {
        return
      }

      e.preventDefault()
      e.stopPropagation()

      this.setTranslate3d(deltaX)
    },

    setTranslate3d (deltaX) {
      const gap = parseInt(this.$refs.table.clientWidth / this.columns)

      if (this.scroll.finished) {
        this.scroll.finished = false

        if (deltaX < 0) {
          this.scroll.left -= gap
          this.scroll.translate += gap
        } else if ((this.$refs.table.clientWidth + this.scroll.translate) - (gap * this.perPage) >= gap) {
          this.scroll.left += gap
          this.scroll.translate -= gap
        }

        if (this.scroll.left < 0) {
          this.scroll.left = 0
        }

        if (this.scroll.translate > 0) {
          this.scroll.translate = 0
        }

        setTimeout(() => { this.scroll.finished = true }, 1000)
      }

      this.$refs.table.style.transform = `translate3d(${this.scroll.translate}px, 0px, 0px)`

      if (this.$refs.scrollbarWrapper) {
        this.$refs.scrollbarWrapper.scrollLeft = this.scroll.left
      }
    }
  },

  head () {
    return {
      title: this.$t('pages.compare.title'),
      link: [
        {
          hid: 'i18n-can',
          rel: 'canonical',
          href: this.$config.appUrl + this.$route.path
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.compare.description')
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$t('pages.compare.title')
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$t('pages.compare.description')
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.logo ? this.logo.image.lazy : this.$config.ogImage
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.appUrl + this.$route.path
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex'
        // },
        // {
        //   hid: 'keywords',
        //   name: 'keywords',
        //   content: ''
        }
      ]
    }
  }
}
