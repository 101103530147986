
import { mapGetters } from 'vuex'

import Cartable from '@/components/Cartable'
import CartFooter from '@/components/CartFooter'

export default {
  components: {
    Cartable,
    CartFooter
  },

  props: {
    type: {
      required: true,
      default () {
        return ''
      },
      type: String
    }
  },

  asyncData ({ from, store }) {
    if (from) {
      store.dispatch('routing/setFrom', (from.fullPath || null))
    }
  },

  data () {
    return {
      height: {
        header: 0,
        item: 0,
        footer: 0
      }
    }
  },

  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      currency: 'currency',
      from: 'routing/from',
      logo: 'logo'
    }),

    totalCartDeliveryPrice () {
      return 0
    },

    totalCartPrice () {
      return this.cart.items.reduce((total, item) => total + item.total, 0)
    },

    cartablesHeight () {
      if (this.height.footer === 0 || this.height.item === 0) {
        return
      }

      return Math.floor((this.$refs[this.type].clientHeight - this.$refs.header.clientHeight - this.height.footer) / this.height.item) * this.height.item
    }
  },

  created () {
    this.$nextTick(() => {
      this.$store.dispatch('cart/setCartType', this.type)
      this.$store.dispatch('cart/syncCart', false)
    })
  },

  mounted () {
    if (this.cart.creditProducts.length &&
      this.cart.type === 'installment' &&
      this.cart.creditProduct !== undefined &&
      Object.keys(this.cart.creditProduct).length === 0 &&
      this.cart.creditProduct.constructor === Object
    ) {
      this.$store.dispatch('cart/setCartCreditProduct', this.cart.creditProducts[0])
    }
  },

  head () {
    return {
      title: this.$t(`pages.${this.type}.title`),
      link: [
        {
          hid: 'i18n-can',
          rel: 'canonical',
          href: this.$config.appUrl + this.$route.path
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t(`pages.${this.type}.description`)
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$t(`pages.${this.type}.title`)
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$t(`pages.${this.type}.description`)
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.logo ? this.logo.image.lazy : this.$config.ogImage
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.appUrl + this.$route.path
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex'
        // },
        // {
        //   hid: 'keywords',
        //   name: 'keywords',
        //   content: ''
        }
      ]
    }
  }
}
