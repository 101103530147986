export const DelayHydration = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/DelayHydration.vue' /* webpackChunkName: "components/delay-hydration" */).then(c => wrapFunctional(c.default || c))
export const HydrationStatus = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/HydrationStatus.vue' /* webpackChunkName: "components/hydration-status" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../components/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const Callcenter = () => import('../../components/Callcenter.vue' /* webpackChunkName: "components/callcenter" */).then(c => wrapFunctional(c.default || c))
export const Cart = () => import('../../components/Cart.vue' /* webpackChunkName: "components/cart" */).then(c => wrapFunctional(c.default || c))
export const CartFooter = () => import('../../components/CartFooter.vue' /* webpackChunkName: "components/cart-footer" */).then(c => wrapFunctional(c.default || c))
export const Cartable = () => import('../../components/Cartable.vue' /* webpackChunkName: "components/cartable" */).then(c => wrapFunctional(c.default || c))
export const Chat = () => import('../../components/Chat.vue' /* webpackChunkName: "components/chat" */).then(c => wrapFunctional(c.default || c))
export const Checkoutable = () => import('../../components/Checkoutable.vue' /* webpackChunkName: "components/checkoutable" */).then(c => wrapFunctional(c.default || c))
export const ChildCategory = () => import('../../components/ChildCategory.vue' /* webpackChunkName: "components/child-category" */).then(c => wrapFunctional(c.default || c))
export const ChildTopic = () => import('../../components/ChildTopic.vue' /* webpackChunkName: "components/child-topic" */).then(c => wrapFunctional(c.default || c))
export const Comments = () => import('../../components/Comments.vue' /* webpackChunkName: "components/comments" */).then(c => wrapFunctional(c.default || c))
export const Condition = () => import('../../components/Condition.vue' /* webpackChunkName: "components/condition" */).then(c => wrapFunctional(c.default || c))
export const ContentPage = () => import('../../components/ContentPage.vue' /* webpackChunkName: "components/content-page" */).then(c => wrapFunctional(c.default || c))
export const Cookie = () => import('../../components/Cookie.vue' /* webpackChunkName: "components/cookie" */).then(c => wrapFunctional(c.default || c))
export const Faq = () => import('../../components/Faq.vue' /* webpackChunkName: "components/faq" */).then(c => wrapFunctional(c.default || c))
export const GoogleMap = () => import('../../components/GoogleMap.vue' /* webpackChunkName: "components/google-map" */).then(c => wrapFunctional(c.default || c))
export const JournalArticle = () => import('../../components/JournalArticle.vue' /* webpackChunkName: "components/journal-article" */).then(c => wrapFunctional(c.default || c))
export const LoadMore = () => import('../../components/LoadMore.vue' /* webpackChunkName: "components/load-more" */).then(c => wrapFunctional(c.default || c))
export const Location = () => import('../../components/Location.vue' /* webpackChunkName: "components/location" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const Magnifier = () => import('../../components/Magnifier.vue' /* webpackChunkName: "components/magnifier" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../components/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const Orderable = () => import('../../components/Orderable.vue' /* webpackChunkName: "components/orderable" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const ParentCategory = () => import('../../components/ParentCategory.vue' /* webpackChunkName: "components/parent-category" */).then(c => wrapFunctional(c.default || c))
export const ParentTopic = () => import('../../components/ParentTopic.vue' /* webpackChunkName: "components/parent-topic" */).then(c => wrapFunctional(c.default || c))
export const Product = () => import('../../components/Product.vue' /* webpackChunkName: "components/product" */).then(c => wrapFunctional(c.default || c))
export const Promo = () => import('../../components/Promo.vue' /* webpackChunkName: "components/promo" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../components/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const Store = () => import('../../components/Store.vue' /* webpackChunkName: "components/store" */).then(c => wrapFunctional(c.default || c))
export const Support = () => import('../../components/Support.vue' /* webpackChunkName: "components/support" */).then(c => wrapFunctional(c.default || c))
export const TileCategory = () => import('../../components/TileCategory.vue' /* webpackChunkName: "components/tile-category" */).then(c => wrapFunctional(c.default || c))
export const TileTopic = () => import('../../components/TileTopic.vue' /* webpackChunkName: "components/tile-topic" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const AuthForgot = () => import('../../components/auth/Forgot.vue' /* webpackChunkName: "components/auth-forgot" */).then(c => wrapFunctional(c.default || c))
export const AuthLogin = () => import('../../components/auth/Login.vue' /* webpackChunkName: "components/auth-login" */).then(c => wrapFunctional(c.default || c))
export const AuthRegister = () => import('../../components/auth/Register.vue' /* webpackChunkName: "components/auth-register" */).then(c => wrapFunctional(c.default || c))
export const ButtonsAuth = () => import('../../components/buttons/Auth.vue' /* webpackChunkName: "components/buttons-auth" */).then(c => wrapFunctional(c.default || c))
export const ButtonsCart = () => import('../../components/buttons/Cart.vue' /* webpackChunkName: "components/buttons-cart" */).then(c => wrapFunctional(c.default || c))
export const ButtonsCompare = () => import('../../components/buttons/Compare.vue' /* webpackChunkName: "components/buttons-compare" */).then(c => wrapFunctional(c.default || c))
export const ButtonsSearch = () => import('../../components/buttons/Search.vue' /* webpackChunkName: "components/buttons-search" */).then(c => wrapFunctional(c.default || c))
export const ChatStartForm = () => import('../../components/chat/StartForm.vue' /* webpackChunkName: "components/chat-start-form" */).then(c => wrapFunctional(c.default || c))
export const ChatTicketForm = () => import('../../components/chat/TicketForm.vue' /* webpackChunkName: "components/chat-ticket-form" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryStep = () => import('../../components/checkout/DeliveryStep.vue' /* webpackChunkName: "components/checkout-delivery-step" */).then(c => wrapFunctional(c.default || c))
export const CheckoutFooterNav = () => import('../../components/checkout/FooterNav.vue' /* webpackChunkName: "components/checkout-footer-nav" */).then(c => wrapFunctional(c.default || c))
export const CheckoutHeaderNav = () => import('../../components/checkout/HeaderNav.vue' /* webpackChunkName: "components/checkout-header-nav" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNote = () => import('../../components/checkout/Note.vue' /* webpackChunkName: "components/checkout-note" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNotification = () => import('../../components/checkout/Notification.vue' /* webpackChunkName: "components/checkout-notification" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentStep = () => import('../../components/checkout/PaymentStep.vue' /* webpackChunkName: "components/checkout-payment-step" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSummary = () => import('../../components/checkout/Summary.vue' /* webpackChunkName: "components/checkout-summary" */).then(c => wrapFunctional(c.default || c))
export const CheckoutUserStep = () => import('../../components/checkout/UserStep.vue' /* webpackChunkName: "components/checkout-user-step" */).then(c => wrapFunctional(c.default || c))
export const ContactsShopCities = () => import('../../components/contacts/ShopCities.vue' /* webpackChunkName: "components/contacts-shop-cities" */).then(c => wrapFunctional(c.default || c))
export const FiltersBadges = () => import('../../components/filters/Badges.vue' /* webpackChunkName: "components/filters-badges" */).then(c => wrapFunctional(c.default || c))
export const FiltersCategories = () => import('../../components/filters/Categories.vue' /* webpackChunkName: "components/filters-categories" */).then(c => wrapFunctional(c.default || c))
export const FiltersCharacteristics = () => import('../../components/filters/Characteristics.vue' /* webpackChunkName: "components/filters-characteristics" */).then(c => wrapFunctional(c.default || c))
export const FiltersChosen = () => import('../../components/filters/Chosen.vue' /* webpackChunkName: "components/filters-chosen" */).then(c => wrapFunctional(c.default || c))
export const FiltersConditionStatus = () => import('../../components/filters/ConditionStatus.vue' /* webpackChunkName: "components/filters-condition-status" */).then(c => wrapFunctional(c.default || c))
export const FiltersLoading = () => import('../../components/filters/Loading.vue' /* webpackChunkName: "components/filters-loading" */).then(c => wrapFunctional(c.default || c))
export const FiltersPrice = () => import('../../components/filters/Price.vue' /* webpackChunkName: "components/filters-price" */).then(c => wrapFunctional(c.default || c))
export const FiltersSortDropdown = () => import('../../components/filters/SortDropdown.vue' /* webpackChunkName: "components/filters-sort-dropdown" */).then(c => wrapFunctional(c.default || c))
export const FiltersStockStatus = () => import('../../components/filters/StockStatus.vue' /* webpackChunkName: "components/filters-stock-status" */).then(c => wrapFunctional(c.default || c))
export const FiltersTouchscreenButtons = () => import('../../components/filters/TouchscreenButtons.vue' /* webpackChunkName: "components/filters-touchscreen-buttons" */).then(c => wrapFunctional(c.default || c))
export const FooterNav = () => import('../../components/footer/Nav.vue' /* webpackChunkName: "components/footer-nav" */).then(c => wrapFunctional(c.default || c))
export const FormsPaymentForm = () => import('../../components/forms/PaymentForm.vue' /* webpackChunkName: "components/forms-payment-form" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavBottom = () => import('../../components/header/NavBottom.vue' /* webpackChunkName: "components/header-nav-bottom" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavTop = () => import('../../components/header/NavTop.vue' /* webpackChunkName: "components/header-nav-top" */).then(c => wrapFunctional(c.default || c))
export const IndexAdvantages = () => import('../../components/index/Advantages.vue' /* webpackChunkName: "components/index-advantages" */).then(c => wrapFunctional(c.default || c))
export const IndexBanner = () => import('../../components/index/Banner.vue' /* webpackChunkName: "components/index-banner" */).then(c => wrapFunctional(c.default || c))
export const IndexJournal = () => import('../../components/index/Journal.vue' /* webpackChunkName: "components/index-journal" */).then(c => wrapFunctional(c.default || c))
export const IndexJournalArticle = () => import('../../components/index/JournalArticle.vue' /* webpackChunkName: "components/index-journal-article" */).then(c => wrapFunctional(c.default || c))
export const IndexLinks = () => import('../../components/index/Links.vue' /* webpackChunkName: "components/index-links" */).then(c => wrapFunctional(c.default || c))
export const IndexMobileCatalog = () => import('../../components/index/MobileCatalog.vue' /* webpackChunkName: "components/index-mobile-catalog" */).then(c => wrapFunctional(c.default || c))
export const IndexPopularProducts = () => import('../../components/index/PopularProducts.vue' /* webpackChunkName: "components/index-popular-products" */).then(c => wrapFunctional(c.default || c))
export const IndexSale = () => import('../../components/index/Sale.vue' /* webpackChunkName: "components/index-sale" */).then(c => wrapFunctional(c.default || c))
export const IndexSlide = () => import('../../components/index/Slide.vue' /* webpackChunkName: "components/index-slide" */).then(c => wrapFunctional(c.default || c))
export const IndexSwiper = () => import('../../components/index/Swiper.vue' /* webpackChunkName: "components/index-swiper" */).then(c => wrapFunctional(c.default || c))
export const JournalShare = () => import('../../components/journal/Share.vue' /* webpackChunkName: "components/journal-share" */).then(c => wrapFunctional(c.default || c))
export const MenusCatalogMenu = () => import('../../components/menus/CatalogMenu.vue' /* webpackChunkName: "components/menus-catalog-menu" */).then(c => wrapFunctional(c.default || c))
export const MenusCatalogMenuDropdown = () => import('../../components/menus/CatalogMenuDropdown.vue' /* webpackChunkName: "components/menus-catalog-menu-dropdown" */).then(c => wrapFunctional(c.default || c))
export const MenusHeaderMenu = () => import('../../components/menus/HeaderMenu.vue' /* webpackChunkName: "components/menus-header-menu" */).then(c => wrapFunctional(c.default || c))
export const MenusMobileMenu = () => import('../../components/menus/MobileMenu.vue' /* webpackChunkName: "components/menus-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const MenusMobileMenuDropdown = () => import('../../components/menus/MobileMenuDropdown.vue' /* webpackChunkName: "components/menus-mobile-menu-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ModalsAuthModal = () => import('../../components/modals/AuthModal.vue' /* webpackChunkName: "components/modals-auth-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsCallbackModal = () => import('../../components/modals/CallbackModal.vue' /* webpackChunkName: "components/modals-callback-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsCartModal = () => import('../../components/modals/CartModal.vue' /* webpackChunkName: "components/modals-cart-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsEmailModal = () => import('../../components/modals/EmailModal.vue' /* webpackChunkName: "components/modals-email-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsEmailToModal = () => import('../../components/modals/EmailToModal.vue' /* webpackChunkName: "components/modals-email-to-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsGalleryModal = () => import('../../components/modals/GalleryModal.vue' /* webpackChunkName: "components/modals-gallery-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsInfoModal = () => import('../../components/modals/InfoModal.vue' /* webpackChunkName: "components/modals-info-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsLocationModal = () => import('../../components/modals/LocationModal.vue' /* webpackChunkName: "components/modals-location-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsNotifyAboutAvailabilityModal = () => import('../../components/modals/NotifyAboutAvailabilityModal.vue' /* webpackChunkName: "components/modals-notify-about-availability-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsPopUpAdModal = () => import('../../components/modals/PopUpAdModal.vue' /* webpackChunkName: "components/modals-pop-up-ad-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsPreorderModal = () => import('../../components/modals/PreorderModal.vue' /* webpackChunkName: "components/modals-preorder-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsRemoveAddressModal = () => import('../../components/modals/RemoveAddressModal.vue' /* webpackChunkName: "components/modals-remove-address-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsRemoveCartModal = () => import('../../components/modals/RemoveCartModal.vue' /* webpackChunkName: "components/modals-remove-cart-modal" */).then(c => wrapFunctional(c.default || c))
export const OrderDelivery = () => import('../../components/order/Delivery.vue' /* webpackChunkName: "components/order-delivery" */).then(c => wrapFunctional(c.default || c))
export const OrderInstallment = () => import('../../components/order/Installment.vue' /* webpackChunkName: "components/order-installment" */).then(c => wrapFunctional(c.default || c))
export const OrderPayment = () => import('../../components/order/Payment.vue' /* webpackChunkName: "components/order-payment" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/product/Card.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCheaperTogetherProductStore = () => import('../../components/product/CheaperTogetherProductStore.vue' /* webpackChunkName: "components/product-cheaper-together-product-store" */).then(c => wrapFunctional(c.default || c))
export const SupportContact = () => import('../../components/support/Contact.vue' /* webpackChunkName: "components/support-contact" */).then(c => wrapFunctional(c.default || c))
export const TradeinCategory = () => import('../../components/tradein/Category.vue' /* webpackChunkName: "components/tradein-category" */).then(c => wrapFunctional(c.default || c))
export const TradeinCharacteristicValue = () => import('../../components/tradein/CharacteristicValue.vue' /* webpackChunkName: "components/tradein-characteristic-value" */).then(c => wrapFunctional(c.default || c))
export const TradeinProduct = () => import('../../components/tradein/Product.vue' /* webpackChunkName: "components/tradein-product" */).then(c => wrapFunctional(c.default || c))
export const TradeinOption = () => import('../../components/tradein/TradeinOption.vue' /* webpackChunkName: "components/tradein-option" */).then(c => wrapFunctional(c.default || c))
export const TradeinTradeinable = () => import('../../components/tradein/Tradeinable.vue' /* webpackChunkName: "components/tradein-tradeinable" */).then(c => wrapFunctional(c.default || c))
export const UserSideMenu = () => import('../../components/user/SideMenu.vue' /* webpackChunkName: "components/user-side-menu" */).then(c => wrapFunctional(c.default || c))
export const ProductPartialsAlternatives = () => import('../../components/product/partials/Alternatives.vue' /* webpackChunkName: "components/product-partials-alternatives" */).then(c => wrapFunctional(c.default || c))
export const ProductPartialsCheaperTogether = () => import('../../components/product/partials/CheaperTogether.vue' /* webpackChunkName: "components/product-partials-cheaper-together" */).then(c => wrapFunctional(c.default || c))
export const ProductPartialsCompare = () => import('../../components/product/partials/Compare.vue' /* webpackChunkName: "components/product-partials-compare" */).then(c => wrapFunctional(c.default || c))
export const ProductPartialsDescription = () => import('../../components/product/partials/Description.vue' /* webpackChunkName: "components/product-partials-description" */).then(c => wrapFunctional(c.default || c))
export const ProductPartialsImages = () => import('../../components/product/partials/Images.vue' /* webpackChunkName: "components/product-partials-images" */).then(c => wrapFunctional(c.default || c))
export const ProductPartialsRating = () => import('../../components/product/partials/Rating.vue' /* webpackChunkName: "components/product-partials-rating" */).then(c => wrapFunctional(c.default || c))
export const ProductPartialsRecent = () => import('../../components/product/partials/Recent.vue' /* webpackChunkName: "components/product-partials-recent" */).then(c => wrapFunctional(c.default || c))
export const ProductPartialsRelated = () => import('../../components/product/partials/Related.vue' /* webpackChunkName: "components/product-partials-related" */).then(c => wrapFunctional(c.default || c))
export const ProductPartialsSimilar = () => import('../../components/product/partials/Similar.vue' /* webpackChunkName: "components/product-partials-similar" */).then(c => wrapFunctional(c.default || c))
export const ProductPartialsStockStatusTag = () => import('../../components/product/partials/StockStatusTag.vue' /* webpackChunkName: "components/product-partials-stock-status-tag" */).then(c => wrapFunctional(c.default || c))
export const ProductPartialsStore = () => import('../../components/product/partials/Store.vue' /* webpackChunkName: "components/product-partials-store" */).then(c => wrapFunctional(c.default || c))
export const ProductPartialsVotes = () => import('../../components/product/partials/Votes.vue' /* webpackChunkName: "components/product-partials-votes" */).then(c => wrapFunctional(c.default || c))
export const ProductPartialsWishlist = () => import('../../components/product/partials/Wishlist.vue' /* webpackChunkName: "components/product-partials-wishlist" */).then(c => wrapFunctional(c.default || c))
export const ProductTabsCharacteristics = () => import('../../components/product/tabs/Characteristics.vue' /* webpackChunkName: "components/product-tabs-characteristics" */).then(c => wrapFunctional(c.default || c))
export const ProductTabsDescription = () => import('../../components/product/tabs/Description.vue' /* webpackChunkName: "components/product-tabs-description" */).then(c => wrapFunctional(c.default || c))
export const ProductTabsGallery = () => import('../../components/product/tabs/Gallery.vue' /* webpackChunkName: "components/product-tabs-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductTabsQuestions = () => import('../../components/product/tabs/Questions.vue' /* webpackChunkName: "components/product-tabs-questions" */).then(c => wrapFunctional(c.default || c))
export const ProductTabsReviews = () => import('../../components/product/tabs/Reviews.vue' /* webpackChunkName: "components/product-tabs-reviews" */).then(c => wrapFunctional(c.default || c))
export const ProductTabsStores = () => import('../../components/product/tabs/Stores.vue' /* webpackChunkName: "components/product-tabs-stores" */).then(c => wrapFunctional(c.default || c))
export const StorePartialsImages = () => import('../../components/store/partials/Images.vue' /* webpackChunkName: "components/store-partials-images" */).then(c => wrapFunctional(c.default || c))
export const StorePartialsRating = () => import('../../components/store/partials/Rating.vue' /* webpackChunkName: "components/store-partials-rating" */).then(c => wrapFunctional(c.default || c))
export const StoreTabsGeneral = () => import('../../components/store/tabs/General.vue' /* webpackChunkName: "components/store-tabs-general" */).then(c => wrapFunctional(c.default || c))
export const StoreTabsProducts = () => import('../../components/store/tabs/Products.vue' /* webpackChunkName: "components/store-tabs-products" */).then(c => wrapFunctional(c.default || c))
export const StoreTabsReviews = () => import('../../components/store/tabs/Reviews.vue' /* webpackChunkName: "components/store-tabs-reviews" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
