
import { mapGetters } from 'vuex'

import { mixin as clickaway } from 'vue-clickaway'

export default {
  mixins: [
    clickaway
  ],

  data () {
    return {
      langswitcher: {
        active: false
      }
    }
  },

  computed: {
    ...mapGetters({
      menus: 'menus',
      socials: 'socials',
      locales: 'locales',
      store: 'store',
      support: 'support/support'
    }),

    availableLocales () {
      return this.locales.filter(i => i.code !== this.$i18n.locale)
    },

    years () {
      const years = {
        established: this.$moment(this.store.established_on).year(),
        current: this.$moment().year()
      }

      if (years.established < years.current) {
        return `${years.established}–${years.current}`
      } else {
        return years.current
      }
    }
  },

  methods: {
    generateLink (slug) {
      if (slug.includes('/')) {
        const slugs = slug.split('/')

        return this.localePath({
          name: 'slug-params',
          params: {
            slug: slugs[0],
            params: slugs[1]
          }
        })
      } else {
        return this.localePath({ name: 'slug', params: { slug } })
      }
    },

    hideLangswitcherDropdown () {
      this.langswitcher.active = false
    }
  }
}
