
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      checkout: 'checkout/checkout'
    })
  }
}
