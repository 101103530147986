
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'
import { defineAsyncComponent } from '@vue/composition-api'

import UserSideMenu from '@/components/user/SideMenu'
import Pagination from '@/components/Pagination'

export default {
  components: {
    UserSideMenu,
    Pagination,
    LazyModalsRemoveAddressModal: defineAsyncComponent(() => import('@/components/modals/RemoveAddressModal'))
  },

  mixins: [
    clickaway
  ],

  middleware: 'auth',

  async asyncData ({ params, $axios, app }) {
    const response = await $axios.$get(`${app.i18n.locale}/${app.store.state.country.iso_code}/user/addresses`, {
      params: {
        sortBy: 'created_at',
        sortOrder: 'desc',
        itemsPerPage: 10,
        page: 1
      }
    })

    return {
      addresses: response.data,
      meta: response.meta
    }
  },

  data () {
    return {
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.$t('pages.user.index.title'),
          to: this.localePath('user'),
          active: false
        },
        {
          title: this.$t('pages.user.addresses.title'),
          to: '#',
          active: true
        }
      ],
      notification: {
        active: false,
        message: '',
        class: ''
      },
      page: 1,
      itemsPerPage: 10,
      loading: false,
      sort: {
        by: 'created_at',
        order: 'desc'
      },
      addresses: [],
      meta: null,
      removeModal: false,
      modal: {
        active: false
      },
      removable: null
    }
  },

  computed: {
    ...mapGetters({
      country: 'country'
    })
  },

  methods: {
    loadAsyncData () {
      this.loading = true

      this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/user/addresses`, {
        params: {
          sortBy: this.sort.by,
          sortOrder: this.sort.order,
          itemsPerPage: this.itemsPerPage,
          page: this.page
        }
      })
        .then((res) => {
          this.addresses = res.data
          this.meta = res.meta
          window.scrollTo({ top: 0, behavior: 'smooth' })
        })
        .catch((e) => {
          this.addresses = []
          this.meta.total = 0
        })
        .finally(() => (this.loading = false))
    },

    onPageChange (page) {
      this.page = page
      this.loadAsyncData()
    },

    onSort (field, order) {
      this.sort.by = field
      this.sort.order = order
      this.loadAsyncData()
    },

    removeCommas (str) {
      str = str.replace(/^\s+|\s+$/gm, '').replace(', ,', ',')

      if (str.startsWith(',')) {
        str = str.slice(1)
      }

      if (str.endsWith(',')) {
        str = str.slice(0, -1)
      }

      return str
    },

    toggleRemoveModal (address = null) {
      this.modal.active = !this.modal.active
      this.removable = address
    },

    removed (res) {
      const index = this.addresses.map(item => item.id).indexOf(this.removable.id)
      this.addresses.splice(index, 1)
      this.meta.total--
      this.toggleRemoveModal()
      this.notification.active = true
      this.notification.message = res.message
      this.notification.class = 'is-success'
    }
  },

  head () {
    return {
      title: this.$t('pages.user.addresses.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.user.addresses.description')
        }
      ]
    }
  }
}
