import { render, staticRenderFns } from "./Description.vue?vue&type=template&id=7d90852a"
import script from "./Description.vue?vue&type=script&lang=js"
export * from "./Description.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductPartialsImages: require('/app/components/product/partials/Images.vue').default,StorePartialsRating: require('/app/components/store/partials/Rating.vue').default,ProductPartialsCheaperTogether: require('/app/components/product/partials/CheaperTogether.vue').default,ProductPartialsWishlist: require('/app/components/product/partials/Wishlist.vue').default,ProductPartialsCompare: require('/app/components/product/partials/Compare.vue').default,ProductPartialsAlternatives: require('/app/components/product/partials/Alternatives.vue').default})
