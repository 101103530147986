
export default {
  props: {
    value: {
      required: true,
      default () {
        return {}
      },
      type: null
    },
    chosenFilters: {
      required: true,
      default () {
        return []
      },
      type: Array
    },
    path: {
      required: true,
      default () {
        return {}
      },
      type: Object
    },
    pathWithParams: {
      required: true,
      default () {
        return {}
      },
      type: Object
    }
  },

  data () {
    return {
      filters: this.value,
      totalFilterRoles: [
        'series'
      ]
    }
  },

  watch: {
    value: {
      handler (val) {
        this.filters = val
      },
      immediate: true
    }
  },

  methods: {
    getFilterCount (filter, filterValue) {
      const chosenFilterValue = this.chosenFilters.findIndex(cf => cf.key === filter.slug && cf.values.findIndex(v => v.slug === filterValue.slug) >= 0) >= 0
      const totalFilterRole = this.totalFilterRoles.includes(filter.role)

      if (this.chosenFilters.length && chosenFilterValue && totalFilterRole && filterValue.total > 0) {
        return filterValue.total
      } else if (this.chosenFilters.length && !chosenFilterValue && totalFilterRole && filterValue.total > 0) {
        return `+${filterValue.total}`
      } else {
        return filterValue.count
      }
    },

    clickOnFilter (event, characteristic, filterValue) {
      if (this.getFilterCount(characteristic, filterValue) === 0) {
        return
      }

      this.$emit('checkFilterOnLinkClick', event)

      this.filters.find(filter => filter.id === characteristic.id).values.find(v => v.slug === filterValue.slug).active = !filterValue.active
    },

    getPath (params) {
      const path = JSON.parse(JSON.stringify(params ? this.pathWithParams : this.path))

      if (params) {
        path.params.params = params
      }

      return this.localePath(path)
    }
  }
}
