export const state = () => ({
  checkout: {
    notification: {
      message: '',
      class: '',
      active: false
    },
    tab: 'user',
    form: {
      user: {
        name: {
          value: '',
          error: ''
        },
        lastname: {
          value: '',
          error: ''
        },
        patronymic: {
          value: '',
          error: ''
        },
        phone: {
          model: '',
          number: '',
          error: ''
        },
        email: {
          value: '',
          error: ''
        }
      },
      delivery: {
        method: null,
        methods: {
          items: []
        },
        cities: {
          items: [],
          major: []
        },
        city: {
          value: {
            id: '',
            name: ''
          },
          query: '',
          name: '',
          error: ''
        },
        filials: {
          items: []
        },
        filial: {
          value: {
            id: '',
            name: ''
          },
          map: false,
          query: '',
          name: '',
          error: ''
        },
        address: {
          street: {
            value: '',
            error: ''
          },
          zip: {
            value: '',
            error: ''
          },
          house: {
            value: '',
            error: ''
          },
          apartment: {
            value: '',
            error: ''
          },
          floor: {
            value: '',
            error: ''
          },
          intercom: {
            value: '',
            error: ''
          }
        },
        recipient: {
          name: {
            value: '',
            error: ''
          },
          lastname: {
            value: '',
            error: ''
          },
          patronymic: {
            value: '',
            error: ''
          },
          phone: {
            model: '',
            number: '',
            error: ''
          },
          email: {
            value: '',
            error: ''
          }
        }
      },
      payment: {
        tab: '',
        method: null,
        // type: 'cart',
        installment: {
          initial: 0,
          name: {
            value: '',
            error: ''
          },
          lastname: {
            value: '',
            error: ''
          },
          patronymic: {
            value: '',
            error: ''
          },
          phone: {
            model: '',
            number: '',
            error: ''
          },
          email: {
            value: '',
            error: ''
          },
          birthday: {
            value: null,
            error: ''
          },
          tin: {
            value: '',
            error: ''
          },
          passport: {
            series: {
              value: '',
              error: ''
            },
            number: {
              value: '',
              error: ''
            },
            issuer: {
              value: '',
              error: ''
            },
            issue_date: {
              value: null,
              error: ''
            },
            expiration_date: {
              value: null,
              error: ''
            }
          },
          address: {
            registration: {
              value: '',
              error: ''
            },
            residence: {
              value: '',
              error: ''
            },
            zip: {
              value: '',
              error: ''
            }
          },
          education: {
            degree: {
              value: '',
              error: ''
            },
            institution: {
              value: '',
              error: ''
            }
          },
          employer: {
            title: {
              value: '',
              error: ''
            },
            phone: {
              model: '',
              number: '',
              error: ''
            },
            address: {
              value: '',
              error: ''
            },
            job_title: {
              value: '',
              error: ''
            },
            occupation: {
              value: '',
              error: ''
            }
          },
          family: {
            status: {
              value: '',
              error: ''
            },
            number_of_children: {
              value: '',
              error: ''
            },
            age_of_children: {
              value: '',
              error: ''
            },
            number_of_inhabitants: {
              value: '',
              error: ''
            }
          },
          third_person: {
            full_name: {
              value: '',
              error: ''
            },
            phone: {
              value: '',
              error: ''
            },
            relations: {
              value: '',
              error: ''
            }
          },
          note: {
            body: ''
          }
        },
        gateway: {
          type: null,
          form: {}
        }
      },
      note: {
        body: ''
      },
      callback: true
    }
  }
})

export const getters = {
  checkout: state => state.checkout
}

export const mutations = {
  SET_USER_NAME (state, payload) {
    state.checkout.form.user.name.value = payload
  },

  SET_USER_LASTNAME (state, payload) {
    state.checkout.form.user.lastname.value = payload
  },

  SET_USER_PATRONYMIC (state, payload) {
    state.checkout.form.user.patronymic.value = payload
  },

  SET_USER_PHONE_MODEL (state, payload) {
    state.checkout.form.user.phone.model = payload
  },

  SET_USER_PHONE (state, payload) {
    state.checkout.form.user.phone.number = payload
  },

  SET_USER_EMAIL_VALUE (state, payload) {
    state.checkout.form.user.email.value = payload
  },

  SET_DELIVERY_CITY_QUERY (state, payload) {
    state.checkout.form.delivery.city.query = payload
  },

  TOGGLE_TAB (state, payload) {
    state.checkout.tab = payload
  },

  VALIDATE_USER_NAME (state, payload) {
    state.checkout.form.user.name.error = payload
  },

  VALIDATE_DELIVERY_RECIPIENT_NAME (state, payload) {
    state.checkout.form.delivery.recipient.name.error = payload
  },

  VALIDATE_INSTALLMENT_NAME (state, payload) {
    state.checkout.form.payment.installment.name.error = payload
  },

  VALIDATE_INSTALLMENT_LASTNAME (state, payload) {
    state.checkout.form.payment.installment.lastname.error = payload
  },

  VALIDATE_INSTALLMENT_PATRONYMIC (state, payload) {
    state.checkout.form.payment.installment.patronymic.error = payload
  },

  SET_INSTALLMENT_PHONE (state, payload) {
    state.checkout.form.payment.installment.phone.number = payload
  },

  VALIDATE_INSTALLMENT_EMAIL (state, payload) {
    state.checkout.form.payment.installment.email.error = payload
  },

  VALIDATE_INSTALLMENT_TIN (state, payload) {
    state.checkout.form.payment.installment.tin.error = payload
  },

  VALIDATE_INSTALLMENT_PASSPORT_SERIES (state, payload) {
    state.checkout.form.payment.installment.passport.series.error = payload
  },

  VALIDATE_INSTALLMENT_PASSPORT_NUMBER (state, payload) {
    state.checkout.form.payment.installment.passport.number.error = payload
  },

  VALIDATE_INSTALLMENT_PASSPORT_ISSUER (state, payload) {
    state.checkout.form.payment.installment.passport.issuer.error = payload
  },

  VALIDATE_INSTALLMENT_PASSPORT_ISSUE_DATE (state, payload) {
    state.checkout.form.payment.installment.passport.issue_date.error = payload
  },

  VALIDATE_INSTALLMENT_PASSPORT_EXPIRATION_DATE (state, payload) {
    state.checkout.form.payment.installment.passport.expiration_date.error = payload
  },

  VALIDATE_INSTALLMENT_ADDRESS_REGISTRATION (state, payload) {
    state.checkout.form.payment.installment.address.registration.error = payload
  },

  VALIDATE_INSTALLMENT_ADDRESS_RESIDENCE (state, payload) {
    state.checkout.form.payment.installment.address.residence.error = payload
  },

  VALIDATE_INSTALLMENT_ADDRESS_ZIP (state, payload) {
    state.checkout.form.payment.installment.address.zip.error = payload
  },

  VALIDATE_INSTALLMENT_EDUCATION_DEGREE (state, payload) {
    state.checkout.form.payment.installment.education.degree.error = payload
  },

  VALIDATE_INSTALLMENT_EDUCATION_INSTITUTION (state, payload) {
    state.checkout.form.payment.installment.education.institution.error = payload
  },

  VALIDATE_INSTALLMENT_EMPLOYER_TITLE (state, payload) {
    state.checkout.form.payment.installment.employer.title.error = payload
  },

  VALIDATE_INSTALLMENT_EMPLOYER_PHONE (state, payload) {
    state.checkout.form.payment.installment.employer.phone.error = payload
  },

  VALIDATE_INSTALLMENT_EMPLOYER_ADDRESS (state, payload) {
    state.checkout.form.payment.installment.employer.address.error = payload
  },

  VALIDATE_INSTALLMENT_EMPLOYER_JOB_TITLE (state, payload) {
    state.checkout.form.payment.installment.employer.job_title.error = payload
  },

  VALIDATE_INSTALLMENT_EMPLOYER_OCCUPATION (state, payload) {
    state.checkout.form.payment.installment.employer.occupation.error = payload
  },

  VALIDATE_INSTALLMENT_FAMILY_STATUS (state, payload) {
    state.checkout.form.payment.installment.family.status.error = payload
  },

  VALIDATE_INSTALLMENT_FAMILY_NUMBER_OF_CHILDREN (state, payload) {
    state.checkout.form.payment.installment.family.number_of_children.error = payload
  },

  VALIDATE_INSTALLMENT_FAMILY_AGE_OF_CHILDREN (state, payload) {
    state.checkout.form.payment.installment.family.age_of_children.error = payload
  },

  VALIDATE_INSTALLMENT_FAMILY_NUMBER_OF_INHABITANTS (state, payload) {
    state.checkout.form.payment.installment.family.number_of_inhabitants.error = payload
  },

  VALIDATE_INSTALLMENT_THIRD_PERSON_FULL_NAME (state, payload) {
    state.checkout.form.payment.installment.third_person.full_name.error = payload
  },

  VALIDATE_INSTALLMENT_THIRD_PERSON_PHONE (state, payload) {
    state.checkout.form.payment.installment.third_person.phone.error = payload
  },

  VALIDATE_INSTALLMENT_THIRD_PERSON_RELATIONS (state, payload) {
    state.checkout.form.payment.installment.third_person.relations.error = payload
  },

  DELIVERY_ADDRESS_STREET_VALUE (state, payload) {
    state.checkout.form.delivery.address.street.value = payload
  },

  DELIVERY_ADDRESS_ZIP_VALUE (state, payload) {
    state.checkout.form.delivery.address.zip.value = payload
  },

  DELIVERY_ADDRESS_HOUSE_VALUE (state, payload) {
    state.checkout.form.delivery.address.house.value = payload
  },

  DELIVERY_ADDRESS_APARTMENT_VALUE (state, payload) {
    state.checkout.form.delivery.address.apartment.value = payload
  },

  DELIVERY_ADDRESS_FLOOR_VALUE (state, payload) {
    state.checkout.form.delivery.address.floor.value = payload
  },

  DELIVERY_ADDRESS_INTERCOM_VALUE (state, payload) {
    state.checkout.form.delivery.address.intercom.value = payload
  },

  DELIVERY_RECIPIENT_NAME_VALUE (state, payload) {
    state.checkout.form.delivery.recipient.name.value = payload
  },

  DELIVERY_RECIPIENT_LASTNAME_VALUE (state, payload) {
    state.checkout.form.delivery.recipient.lastname.value = payload
  },

  DELIVERY_RECIPIENT_PATRONYMIC_VALUE (state, payload) {
    state.checkout.form.delivery.recipient.patronymic.value = payload
  },

  DELIVERY_RECIPIENT_PHONE_MODEL (state, payload) {
    state.checkout.form.delivery.recipient.phone.model = payload
  },

  DELIVERY_RECIPIENT_EMAIL_VALUE (state, payload) {
    state.checkout.form.delivery.recipient.email.model = payload
  },

  SET_USER_PHONE_ERROR (state, payload) {
    state.checkout.form.user.phone.error = payload
  },

  SET_DELIVERY_RECIPIENT_PHONE (state, payload) {
    state.checkout.form.delivery.recipient.phone.number = payload
  },

  SET_DELIVERY_RECIPIENT_PHONE_ERROR (state, payload) {
    state.checkout.form.delivery.recipient.phone.error = payload
  },

  VALIDATE_USER_EMAIL (state, payload) {
    state.checkout.form.user.email.error = payload
  },

  VALIDATE_DELIVERY_RECIPIENT_EMAIL (state, payload) {
    state.checkout.form.delivery.recipient.email.error = payload
  },

  SET_DELIVERY_CITY (state, payload) {
    state.checkout.form.delivery.city.value = payload
  },

  SET_DELIVERY_METHOD_ITEMS (state, payload) {
    state.checkout.form.delivery.methods.items = payload
  },

  SET_DELIVERY_METHOD (state, payload) {
    state.checkout.form.delivery.method = payload
  },

  SET_PAYMENT_METHOD (state, payload) {
    state.checkout.form.payment.method = payload
  },

  SET_DELIVERY_MAJOR_CITIES (state, payload) {
    state.checkout.form.delivery.cities.major = payload
  },

  SET_DELIVERY_CITIES_ITEMS (state, payload) {
    state.checkout.form.delivery.cities.items = payload
  },

  SET_DELIVERY_FILIAL (state, payload) {
    state.checkout.form.delivery.filial.value = payload
  },

  SET_DELIVERY_FILIAL_MAP (state, payload) {
    state.checkout.form.delivery.filial.map = payload
  },

  SET_DELIVERY_FILIAL_QUERY (state, payload) {
    state.checkout.form.delivery.filial.query = payload
  },

  SET_DELIVERY_FILIALS (state, payload) {
    state.checkout.form.delivery.methods.items.find(method => method.id === payload.deliveryMethodId).filials = payload.filials
  },

  SET_PAYMENT_TAB (state, payload) {
    state.checkout.form.payment.tab = payload
  },

  // SET_PAYMENT_TYPE (state, payload) {
  //   state.checkout.form.payment.type = payload
  // },

  SET_NOTE_BODY (state, payload) {
    state.checkout.form.note.body = payload
  },

  SET_CALLBACK (state, payload) {
    state.checkout.form.callback = payload
  },

  SET_INSTALLMENT_INITIAL (state, payload) {
    state.checkout.form.payment.installment.initial = payload
  },

  SET_INSTALLMENT_NAME (state, payload) {
    state.checkout.form.payment.installment.name.value = payload
  },

  SET_INSTALLMENT_LASTNAME (state, payload) {
    state.checkout.form.payment.installment.lastname.value = payload
  },

  SET_INSTALLMENT_PATRONYMIC (state, payload) {
    state.checkout.form.payment.installment.patronymic.value = payload
  },

  INSTALLMENT_PHONE_MODEL (state, payload) {
    state.checkout.form.payment.installment.phone.model = payload
  },

  SET_INSTALLMENT_PHONE_ERROR (state, payload) {
    state.checkout.form.payment.installment.phone.error = payload
  },

  SET_INSTALLMENT_EMAIL (state, payload) {
    state.checkout.form.payment.installment.email.value = payload
  },

  SET_INSTALLMENT_BIRTHDAY (state, payload) {
    state.checkout.form.payment.installment.birthday.value = payload
  },

  SET_INSTALLMENT_TIN (state, payload) {
    state.checkout.form.payment.installment.tin.value = payload
  },

  SET_INSTALLMENT_PASSPORT_SERIES (state, payload) {
    state.checkout.form.payment.installment.passport.series.value = payload
  },

  SET_INSTALLMENT_PASSPORT_NUMBER (state, payload) {
    state.checkout.form.payment.installment.passport.number.value = payload
  },

  SET_INSTALLMENT_PASSPORT_ISSUER (state, payload) {
    state.checkout.form.payment.installment.passport.issuer.value = payload
  },

  SET_INSTALLMENT_PASSPORT_ISSUE_DATE (state, payload) {
    state.checkout.form.payment.installment.passport.issue_date.value = payload
  },

  SET_INSTALLMENT_PASSPORT_EXPIRATION_DATE (state, payload) {
    state.checkout.form.payment.installment.passport.expiration_date.value = payload
  },

  SET_INSTALLMENT_ADDRESS_REGISTRATION (state, payload) {
    state.checkout.form.payment.installment.address.registration.value = payload
  },

  SET_INSTALLMENT_ADDRESS_RESIDENCE (state, payload) {
    state.checkout.form.payment.installment.address.residence.value = payload
  },

  SET_INSTALLMENT_ADDRESS_ZIP (state, payload) {
    state.checkout.form.payment.installment.address.zip.value = payload
  },

  SET_INSTALLMENT_EDUCATION_DEGREE (state, payload) {
    state.checkout.form.payment.installment.education.degree.value = payload
  },

  SET_INSTALLMENT_EDUCATION_INSTITUTION (state, payload) {
    state.checkout.form.payment.installment.education.institution.value = payload
  },

  SET_INSTALLMENT_EMPLOYER_TITLE (state, payload) {
    state.checkout.form.payment.installment.employer.title.value = payload
  },

  SET_INSTALLMENT_EMPLOYER_PHONE_MODEL (state, payload) {
    state.checkout.form.payment.installment.employer.phone.model = payload
  },

  SET_INSTALLMENT_EMPLOYER_PHONE (state, payload) {
    state.checkout.form.payment.installment.employer.phone.number = payload
  },

  SET_INSTALLMENT_EMPLOYER_PHONE_ERROR (state, payload) {
    state.checkout.form.payment.installment.employer.phone.error = payload
  },

  SET_INSTALLMENT_EMPLOYER_ADDRESS (state, payload) {
    state.checkout.form.payment.installment.employer.address.value = payload
  },

  SET_INSTALLMENT_EMPLOYER_JOB_TITLE (state, payload) {
    state.checkout.form.payment.installment.employer.job_title.value = payload
  },

  SET_INSTALLMENT_EMPLOYER_OCCUPATION (state, payload) {
    state.checkout.form.payment.installment.employer.occupation.value = payload
  },

  SET_INSTALLMENT_FAMILY_STATUS (state, payload) {
    state.checkout.form.payment.installment.family.status.value = payload
  },

  SET_INSTALLMENT_FAMILY_NUMBER_OF_CHILDREN (state, payload) {
    state.checkout.form.payment.installment.family.number_of_children.value = payload
  },

  SET_INSTALLMENT_FAMILY_AGE_OF_CHILDREN (state, payload) {
    state.checkout.form.payment.installment.family.age_of_children.value = payload
  },

  SET_INSTALLMENT_FAMILY_NUMBER_OF_INHABITANTS (state, payload) {
    state.checkout.form.payment.installment.family.number_of_inhabitants.value = payload
  },

  SET_INSTALLMENT_THIRD_PERSON_FULL_NAME (state, payload) {
    state.checkout.form.payment.installment.third_person.full_name.value = payload
  },

  SET_INSTALLMENT_THIRD_PERSON_PHONE (state, payload) {
    state.checkout.form.payment.installment.third_person.phone.value = payload
  },

  SET_INSTALLMENT_THIRD_RELATIONS (state, payload) {
    state.checkout.form.payment.installment.third_person.relations.value = payload
  },

  SET_INSTALLMENT_NOTE_BODY (state, payload) {
    state.checkout.form.payment.installment.note.body = payload
  },

  SET_PAYMENT_GATEWAY_TYPE (state, payload) {
    state.checkout.form.payment.gateway.type = payload
  },

  SET_PAYMENT_GATEWAY_FORM (state, payload) {
    state.checkout.form.payment.gateway.form = payload
  },

  SET_NOTIFICATION_MESSAGE (state, payload) {
    state.checkout.notification.message = payload
  },

  SET_NOTIFICATION_CLASS (state, payload) {
    state.checkout.notification.class = payload
  },

  SET_NOTIFICATION_ACTIVE (state, payload) {
    state.checkout.notification.active = payload
  }
}

export const actions = {
  setUserNameValue ({ commit }, payload) {
    commit('SET_USER_NAME', payload)
  },

  setUserLastnameValue ({ commit }, payload) {
    commit('SET_USER_LASTNAME', payload)
  },

  setUserPatronymicValue ({ commit }, payload) {
    commit('SET_USER_PATRONYMIC', payload)
  },

  setUserPhoneModel ({ commit }, payload) {
    commit('SET_USER_PHONE_MODEL', payload)
  },

  setUserPhone ({ commit }, payload) {
    commit('SET_USER_PHONE', payload)
  },

  setUserEmailValue ({ commit }, payload) {
    commit('SET_USER_EMAIL_VALUE', payload)
  },

  toggleTab ({ commit }, payload) {
    commit('TOGGLE_TAB', payload)
  },

  validateUserName ({ commit }, payload) {
    commit('VALIDATE_USER_NAME', payload)
  },

  validateDeliveryRecipientName ({ commit }, payload) {
    commit('VALIDATE_DELIVERY_RECIPIENT_NAME', payload)
  },

  setDeliveryAddressStreetValue ({ commit }, payload) {
    commit('DELIVERY_ADDRESS_STREET_VALUE', payload)
  },

  setDeliveryAddressZipValue ({ commit }, payload) {
    commit('DELIVERY_ADDRESS_ZIP_VALUE', payload)
  },

  setDeliveryAddressHouseValue ({ commit }, payload) {
    commit('DELIVERY_ADDRESS_HOUSE_VALUE', payload)
  },

  setDeliveryAddressApartmentValue ({ commit }, payload) {
    commit('DELIVERY_ADDRESS_APARTMENT_VALUE', payload)
  },

  setDeliveryAddressFloorValue ({ commit }, payload) {
    commit('DELIVERY_ADDRESS_FLOOR_VALUE', payload)
  },

  setDeliveryAddressIntercomValue ({ commit }, payload) {
    commit('DELIVERY_ADDRESS_INTERCOM_VALUE', payload)
  },

  setDeliveryRecipientNameValue ({ commit }, payload) {
    commit('DELIVERY_RECIPIENT_NAME_VALUE', payload)
  },

  setDeliveryRecipientLastnameValue ({ commit }, payload) {
    commit('DELIVERY_RECIPIENT_LASTNAME_VALUE', payload)
  },

  setDeliveryRecipientPatronymicValue ({ commit }, payload) {
    commit('DELIVERY_RECIPIENT_PATRONYMIC_VALUE', payload)
  },

  setDeliveryRecipientPhoneModel ({ commit }, payload) {
    commit('DELIVERY_RECIPIENT_PHONE_MODEL', payload)
  },

  setDeliveryRecipientEmailValue ({ commit }, payload) {
    commit('DELIVERY_RECIPIENT_EMAIL_VALUE', payload)
  },

  setUserPhoneError ({ commit }, payload) {
    commit('SET_USER_PHONE_ERROR', payload)
  },

  setDeliveryRecipientPhone ({ commit }, payload) {
    commit('SET_DELIVERY_RECIPIENT_PHONE', payload)
  },

  setDeliveryRecipientPhoneError ({ commit }, payload) {
    commit('SET_DELIVERY_RECIPIENT_PHONE_ERROR', payload)
  },

  validateUserEmail ({ commit }, payload) {
    commit('VALIDATE_USER_EMAIL', payload)
  },

  validateDeliveryRecipientEmail ({ commit }, payload) {
    commit('VALIDATE_DELIVERY_RECIPIENT_EMAIL', payload)
  },

  setDeliveryCity ({ commit }, payload) {
    commit('SET_DELIVERY_CITY', payload)
  },

  setDeliveryCityQuery ({ commit }, payload) {
    commit('SET_DELIVERY_CITY_QUERY', payload)
  },

  setDeliveryMethodItems ({ commit }, payload) {
    commit('SET_DELIVERY_METHOD_ITEMS', payload)
  },

  setDeliveryMethod ({ commit }, payload) {
    commit('SET_DELIVERY_METHOD', payload)
  },

  setPaymentMethod ({ commit }, payload) {
    commit('SET_PAYMENT_METHOD', payload)
  },

  setDeliveryMajorCities ({ commit }, payload) {
    commit('SET_DELIVERY_MAJOR_CITIES', payload)
  },

  setDeliveryCitiesItems ({ commit }, payload) {
    commit('SET_DELIVERY_CITIES_ITEMS', payload)
  },

  setDeliveryFilial ({ commit }, payload) {
    commit('SET_DELIVERY_FILIAL', payload)
  },

  setDeliveryFilialMap ({ commit }, payload) {
    commit('SET_DELIVERY_FILIAL_MAP', payload)
  },

  setDeliveryFilialQuery ({ commit }, payload) {
    commit('SET_DELIVERY_FILIAL_QUERY', payload)
  },

  setDeliveryFilials ({ commit }, payload) {
    commit('SET_DELIVERY_FILIALS', payload)
  },

  validateInstallmentName ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_NAME', payload)
  },

  validateInstallmentLastname ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_LASTNAME', payload)
  },

  validateInstallmentPatronymic ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_PATRONYMIC', payload)
  },

  setInstallmentPhoneModel ({ commit }, payload) {
    commit('INSTALLMENT_PHONE_MODEL', payload)
  },

  setInstallmentPhoneError ({ commit }, payload) {
    commit('SET_INSTALLMENT_PHONE_ERROR', payload)
  },

  validateInstallmentEmail ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_EMAIL', payload)
  },

  validateInstallmentTin ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_TIN', payload)
  },

  validateInstallmentPassportSeries ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_PASSPORT_SERIES', payload)
  },

  validateInstallmentPassportNumber ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_PASSPORT_NUMBER', payload)
  },

  validateInstallmentPassportIssuer ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_PASSPORT_ISSUER', payload)
  },

  validateInstallmentPassportIssueDate ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_PASSPORT_ISSUE_DATE', payload)
  },

  validateInstallmentPassportExpirationDate ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_PASSPORT_EXPIRATION_DATE', payload)
  },

  validateInstallmentAddressRegistration ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_ADDRESS_REGISTRATION', payload)
  },

  validateInstallmentAddressResidence ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_ADDRESS_RESIDENCE', payload)
  },

  validateInstallmentAddressZip ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_ADDRESS_ZIP', payload)
  },

  validateInstallmentEducationDegree ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_EDUCATION_DEGREE', payload)
  },

  validateInstallmentEducationInstitution ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_EDUCATION_INSTITUTION', payload)
  },

  validateInstallmentEmployerTitle ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_EMPLOYER_TITLE', payload)
  },

  validateInstallmentEmployerPhone ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_EMPLOYER_PHONE', payload)
  },

  validateInstallmentEmployerAddress ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_EMPLOYER_ADDRESS', payload)
  },

  validateInstallmentEmployerJobTitle ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_EMPLOYER_JOB_TITLE', payload)
  },

  validateInstallmentEmployerOccupation ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_EMPLOYER_OCCUPATION', payload)
  },

  validateInstallmentFamilyStatus ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_FAMILY_STATUS', payload)
  },

  validateInstallmentFamilyNumberOfChildren ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_FAMILY_NUMBER_OF_CHILDREN', payload)
  },

  validateInstallmentFamilyAgeOfChildren ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_FAMILY_AGE_OF_CHILDREN', payload)
  },

  validateInstallmentFamilyNumberOfInhabitants ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_FAMILY_NUMBER_OF_INHABITANTS', payload)
  },

  validateInstallmentThirdPersonFullName ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_THIRD_PERSON_FULL_NAME', payload)
  },

  validateInstallmentThirdPersonPersonPhone ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_THIRD_PERSON_PHONE', payload)
  },

  validateInstallmentThirdPersonRelations ({ commit }, payload) {
    commit('VALIDATE_INSTALLMENT_THIRD_PERSON_RELATIONS', payload)
  },

  setPaymentTab ({ commit }, payload) {
    commit('SET_PAYMENT_TAB', payload)
  },

  // setPaymentType ({ commit }, payload) {
  //   commit('SET_PAYMENT_TYPE', payload)
  // },

  setNoteBody ({ commit }, payload) {
    commit('SET_NOTE_BODY', payload)
  },

  setCallback ({ commit }, payload) {
    commit('SET_CALLBACK', payload)
  },

  setInstallmentInitial ({ commit }, payload) {
    commit('SET_INSTALLMENT_INITIAL', payload)
  },

  setInstallmentName ({ commit }, payload) {
    commit('SET_INSTALLMENT_NAME', payload)
  },

  setInstallmentLastname ({ commit }, payload) {
    commit('SET_INSTALLMENT_LASTNAME', payload)
  },

  setInstallmentPatronymic ({ commit }, payload) {
    commit('SET_INSTALLMENT_PATRONYMIC', payload)
  },

  setInstallmentPhone ({ commit }, payload) {
    commit('SET_INSTALLMENT_PHONE', payload)
  },

  setInstallmentEmail ({ commit }, payload) {
    commit('SET_INSTALLMENT_EMAIL', payload)
  },

  setInstallmentBirthday ({ commit }, payload) {
    commit('SET_INSTALLMENT_BIRTHDAY', payload)
  },

  setInstallmentTin ({ commit }, payload) {
    commit('SET_INSTALLMENT_TIN', payload)
  },

  setInstallmentPassportSeries ({ commit }, payload) {
    commit('SET_INSTALLMENT_PASSPORT_SERIES', payload)
  },

  setInstallmentPassportNumber ({ commit }, payload) {
    commit('SET_INSTALLMENT_PASSPORT_NUMBER', payload)
  },

  setInstallmentPassportIssuer ({ commit }, payload) {
    commit('SET_INSTALLMENT_PASSPORT_ISSUER', payload)
  },

  setInstallmentPassportIssueDate ({ commit }, payload) {
    commit('SET_INSTALLMENT_PASSPORT_ISSUE_DATE', payload)
  },

  setInstallmentPassportExpirationDate ({ commit }, payload) {
    commit('SET_INSTALLMENT_PASSPORT_EXPIRATION_DATE', payload)
  },

  setInstallmentAddressRegistration ({ commit }, payload) {
    commit('SET_INSTALLMENT_ADDRESS_REGISTRATION', payload)
  },

  setInstallmentAddressResidence ({ commit }, payload) {
    commit('SET_INSTALLMENT_ADDRESS_RESIDENCE', payload)
  },

  setInstallmentAddressZip ({ commit }, payload) {
    commit('SET_INSTALLMENT_ADDRESS_ZIP', payload)
  },

  setInstallmentEducationDegree ({ commit }, payload) {
    commit('SET_INSTALLMENT_EDUCATION_DEGREE', payload)
  },

  setInstallmentEducationInstitution ({ commit }, payload) {
    commit('SET_INSTALLMENT_EDUCATION_INSTITUTION', payload)
  },

  setInstallmentEmployerTitle ({ commit }, payload) {
    commit('SET_INSTALLMENT_EMPLOYER_TITLE', payload)
  },

  setInstallmentEmployerPhoneModel ({ commit }, payload) {
    commit('SET_INSTALLMENT_EMPLOYER_PHONE_MODEL', payload)
  },

  setInstallmentEmployerPhone ({ commit }, payload) {
    commit('SET_INSTALLMENT_EMPLOYER_PHONE', payload)
  },

  setInstallmentEmployerPhoneError ({ commit }, payload) {
    commit('SET_INSTALLMENT_EMPLOYER_PHONE_ERROR', payload)
  },

  setInstallmentEmployerAddress ({ commit }, payload) {
    commit('SET_INSTALLMENT_EMPLOYER_ADDRESS', payload)
  },

  setInstallmentEmployerJobTitle ({ commit }, payload) {
    commit('SET_INSTALLMENT_EMPLOYER_JOB_TITLE', payload)
  },

  setInstallmentEmployerOccupation ({ commit }, payload) {
    commit('SET_INSTALLMENT_EMPLOYER_OCCUPATION', payload)
  },

  setInstallmentFamilyStatus ({ commit }, payload) {
    commit('SET_INSTALLMENT_FAMILY_STATUS', payload)
  },

  setInstallmentFamilyNumberOfChildren ({ commit }, payload) {
    commit('SET_INSTALLMENT_FAMILY_NUMBER_OF_CHILDREN', payload)
  },

  setInstallmentFamilyAgeOfChildren ({ commit }, payload) {
    commit('SET_INSTALLMENT_FAMILY_AGE_OF_CHILDREN', payload)
  },

  setInstallmentFamilyNumberOfInhabitants ({ commit }, payload) {
    commit('SET_INSTALLMENT_FAMILY_NUMBER_OF_INHABITANTS', payload)
  },

  setInstallmentThirdPersonFullName ({ commit }, payload) {
    commit('SET_INSTALLMENT_THIRD_PERSON_FULL_NAME', payload)
  },

  setInstallmentThirdPersonPersonPhone ({ commit }, payload) {
    commit('SET_INSTALLMENT_THIRD_PERSON_PHONE', payload)
  },

  setInstallmentThirdPersonRelations ({ commit }, payload) {
    commit('SET_INSTALLMENT_THIRD_PERSON_RELATIONS', payload)
  },

  setInstallmentNoteBody ({ commit }, payload) {
    commit('SET_INSTALLMENT_NOTE_BODY', payload)
  },

  setPaymentGatewayType ({ commit }, payload) {
    commit('SET_PAYMENT_GATEWAY_TYPE', payload)
  },

  setPaymentGatewayForm ({ commit }, payload) {
    commit('SET_PAYMENT_GATEWAY_FORM', payload)
  },

  setNotificationMessage ({ commit }, payload) {
    commit('SET_NOTIFICATION_MESSAGE', payload)
  },

  setNotificationClass ({ commit }, payload) {
    commit('SET_NOTIFICATION_CLASS', payload)
  },

  setNotificationActive ({ commit }, payload) {
    commit('SET_NOTIFICATION_ACTIVE', payload)
  }
}
