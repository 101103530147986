import { render, staticRenderFns } from "./contacts.vue?vue&type=template&id=9cda4ca0"
import script from "./contacts.vue?vue&type=script&lang=js"
export * from "./contacts.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumbs: require('/app/components/Breadcrumbs.vue').default,ContactsShopCities: require('/app/components/contacts/ShopCities.vue').default,GoogleMap: require('/app/components/GoogleMap.vue').default})
