import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5c7e45bd"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumbs: require('/app/components/Breadcrumbs.vue').default,UserSideMenu: require('/app/components/user/SideMenu.vue').default,OrderDelivery: require('/app/components/order/Delivery.vue').default,Pagination: require('/app/components/Pagination.vue').default})
