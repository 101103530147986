export const state = () => ({
  compare: {
    id: null,
    items: [],
    characteristics: [],
    loading: true,
    toggling: false
  }
})

export const getters = {
  compare: state => state.compare
}

export const mutations = {
  SET_COMPARE_LOADING: (state, loading) => { state.compare.loading = loading },
  SET_COMPARE_TOGGLING: (state, toggling) => { state.compare.toggling = toggling },
  SET_COMPARE: (state, compare) => {
    if ('id' in compare) {
      state.compare.id = compare.id
    }

    if ('items' in compare) {
      state.compare.items = compare.items
    }

    if ('characteristics' in compare) {
      state.compare.characteristics = compare.characteristics
    }
  },
  TOGGLE_COMPARE: (state, { item }) => {
    const found = state.compare.items.find(
      compareItem => compareItem.compareable_id === item.id
    )

    if (found) {
      const index = state.compare.items.indexOf(found)
      if (index > -1) {
        state.compare.items.splice(index, 1)
      }
    } else {
      state.compare.items.push({
        compareable_id: item.id,
        image: item.images[0],
        title: item.title,
        class: item.class,
        store: item.store,
        page: item.page,
        price: item.store.price
      })
    }
  },
  REMOVE_COMPARE: (state, { item }) => {
    state.compare.items.splice(state.compare.items.indexOf(item), 1)
  },
  SAVE_COMPARE_ID_TO_LOCALSTORAGE: (state) => {
    window.localStorage.setItem('compare', JSON.stringify({
      id: state.compare.id
    }))
  }
}

export const actions = {
  setCompareLoading ({ commit }, loading) {
    commit('SET_COMPARE_LOADING', loading)
  },

  async setCompare ({ commit }, compare) {
    commit('SET_COMPARE_LOADING', true)
    await commit('SET_COMPARE', compare)
    await commit('SAVE_COMPARE_ID_TO_LOCALSTORAGE')
    commit('SET_COMPARE_LOADING', false)
  },

  async toggleCompare ({ commit, state, rootState }, { item }) {
    commit('TOGGLE_COMPARE', { item })
    commit('SET_COMPARE_LOADING', true)
    commit('SET_COMPARE_TOGGLING', true)

    const country = rootState.country

    if (state.compare.id === null) {
      const compare = await this.$axios.$post(`${this.$i18n.locale}/${country.iso_code}/compares`, state.compare)

      commit('SET_COMPARE', compare.data)
      commit('SAVE_COMPARE_ID_TO_LOCALSTORAGE')
    } else {
      const compare = await this.$axios.$put(`${this.$i18n.locale}/${country.iso_code}/compares/${state.compare.id}`, state.compare)

      commit('SET_COMPARE', compare.data)
    }

    // if (rootState.auth.loggedIn) {
    //   rootState.auth.fetchUser()
    // }
    commit('SET_COMPARE_LOADING', false)
    commit('SET_COMPARE_TOGGLING', false)
  },

  async removeCompare ({ commit, state, rootState }, { item }) {
    commit('SET_COMPARE_LOADING', true)
    commit('REMOVE_COMPARE', { item })

    const country = rootState.country

    if (state.compare.id === null) {
      const compare = await this.$axios.$post(`${this.$i18n.locale}/${country.iso_code}/compares`, state.compare)

      commit('SET_COMPARE', compare.data)
      commit('SAVE_COMPARE_ID_TO_LOCALSTORAGE')
    } else {
      const compare = await this.$axios.$put(`${this.$i18n.locale}/${country.iso_code}/compares/${state.compare.id}`, state.compare)

      commit('SET_COMPARE', compare.data)
    }

    // if (rootState.auth.loggedIn) {
    //   rootState.auth.fetchUser()
    // }
    commit('SET_COMPARE_LOADING', false)
  },

  async syncCompare ({ commit, state, rootState }) {
    commit('SET_COMPARE_LOADING', true)

    const country = rootState.country

    if (state.compare.id === null) {
      const compare = await this.$axios.$post(`${this.$i18n.locale}/${country.iso_code}/compares`, state.compare)

      commit('SET_COMPARE', compare.data)
    } else {
      try {
        const compare = await this.$axios.$put(`${this.$i18n.locale}/${country.iso_code}/compares/${state.compare.id}`, state.compare)

        commit('SET_COMPARE', compare.data)
      } catch (e) {
        const compare = await this.$axios.$post(`${this.$i18n.locale}/${country.iso_code}/compares`, state.compare)

        commit('SET_COMPARE', compare.data)
      }
    }

    commit('SAVE_COMPARE_ID_TO_LOCALSTORAGE')

    // if (rootState.auth.loggedIn) {
    //   rootState.auth.fetchUser()
    // }

    commit('SET_COMPARE_LOADING', false)
  }
}
