import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0e433b93 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _49a49b0c = () => interopDefault(import('../pages/calculators/index.vue' /* webpackChunkName: "pages/calculators/index" */))
const _e08117ec = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _1cb34a30 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _71dba56b = () => interopDefault(import('../pages/compare.vue' /* webpackChunkName: "pages/compare" */))
const _4e57da66 = () => interopDefault(import('../pages/constructors/index.vue' /* webpackChunkName: "pages/constructors/index" */))
const _12bb0ebd = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "pages/contacts" */))
const _45dcc2e3 = () => interopDefault(import('../pages/credit.vue' /* webpackChunkName: "pages/credit" */))
const _23abdc44 = () => interopDefault(import('../pages/delivery.vue' /* webpackChunkName: "pages/delivery" */))
const _6a45d156 = () => interopDefault(import('../pages/employment.vue' /* webpackChunkName: "pages/employment" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _ca324bc8 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _554ad60d = () => interopDefault(import('../pages/forgot.vue' /* webpackChunkName: "pages/forgot" */))
const _6cc30cff = () => interopDefault(import('../pages/installment.vue' /* webpackChunkName: "pages/installment" */))
const _898eb0f2 = () => interopDefault(import('../pages/installments/index.vue' /* webpackChunkName: "pages/installments/index" */))
const _49d7f682 = () => interopDefault(import('../pages/legal.vue' /* webpackChunkName: "pages/legal" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _3b011b86 = () => interopDefault(import('../pages/news.vue' /* webpackChunkName: "pages/news" */))
const _c45620e2 = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _37e8fae4 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _039c8c74 = () => interopDefault(import('../pages/redirect.vue' /* webpackChunkName: "pages/redirect" */))
const _5fba350d = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _177ec73b = () => interopDefault(import('../pages/service.vue' /* webpackChunkName: "pages/service" */))
const _1c176355 = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _500e56e6 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _28e626b3 = () => interopDefault(import('../pages/thanks.vue' /* webpackChunkName: "pages/thanks" */))
const _4c0ca95c = () => interopDefault(import('../pages/tradein/index.vue' /* webpackChunkName: "pages/tradein/index" */))
const _152321f8 = () => interopDefault(import('../pages/user/index.vue' /* webpackChunkName: "pages/user/index" */))
const _76f032e6 = () => interopDefault(import('../pages/warranty.vue' /* webpackChunkName: "pages/warranty" */))
const _669e2d66 = () => interopDefault(import('../pages/constructors/apparel.vue' /* webpackChunkName: "pages/constructors/apparel" */))
const _4baddaf6 = () => interopDefault(import('../pages/tradein/checkout.vue' /* webpackChunkName: "pages/tradein/checkout" */))
const _068b9e0e = () => interopDefault(import('../pages/tradein/thanks.vue' /* webpackChunkName: "pages/tradein/thanks" */))
const _1403264b = () => interopDefault(import('../pages/user/addresses/index.vue' /* webpackChunkName: "pages/user/addresses/index" */))
const _0b0db472 = () => interopDefault(import('../pages/user/orders/index.vue' /* webpackChunkName: "pages/user/orders/index" */))
const _571c5536 = () => interopDefault(import('../pages/user/password.vue' /* webpackChunkName: "pages/user/password" */))
const _83ee1ac4 = () => interopDefault(import('../pages/user/preferences.vue' /* webpackChunkName: "pages/user/preferences" */))
const _bdf3a8e2 = () => interopDefault(import('../pages/user/wishlist.vue' /* webpackChunkName: "pages/user/wishlist" */))
const _14c97c00 = () => interopDefault(import('../pages/installments/privatbank/cancel.vue' /* webpackChunkName: "pages/installments/privatbank/cancel" */))
const _cdca6226 = () => interopDefault(import('../pages/installments/privatbank/success.vue' /* webpackChunkName: "pages/installments/privatbank/success" */))
const _3b6db8dc = () => interopDefault(import('../pages/payment/liqpay/cancel.vue' /* webpackChunkName: "pages/payment/liqpay/cancel" */))
const _7b90199b = () => interopDefault(import('../pages/payment/liqpay/success.vue' /* webpackChunkName: "pages/payment/liqpay/success" */))
const _f990ce5a = () => interopDefault(import('../pages/payment/paypal/cancel.vue' /* webpackChunkName: "pages/payment/paypal/cancel" */))
const _0f1f670c = () => interopDefault(import('../pages/payment/paypal/success.vue' /* webpackChunkName: "pages/payment/paypal/success" */))
const _5290f74c = () => interopDefault(import('../pages/user/addresses/_address.vue' /* webpackChunkName: "pages/user/addresses/_address" */))
const _86aae742 = () => interopDefault(import('../pages/user/orders/_order.vue' /* webpackChunkName: "pages/user/orders/_order" */))
const _8fca26b0 = () => interopDefault(import('../pages/search/_params.vue' /* webpackChunkName: "pages/search/_params" */))
const _a7b013cc = () => interopDefault(import('../pages/auth/_driver/callback.vue' /* webpackChunkName: "pages/auth/_driver/callback" */))
const _92ba9740 = () => interopDefault(import('../pages/installments/_company/cancel.vue' /* webpackChunkName: "pages/installments/_company/cancel" */))
const _6eef5b13 = () => interopDefault(import('../pages/installments/_company/progress.vue' /* webpackChunkName: "pages/installments/_company/progress" */))
const _3268a28d = () => interopDefault(import('../pages/installments/_company/success.vue' /* webpackChunkName: "pages/installments/_company/success" */))
const _f6ee20da = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))
const _70b7b0ae = () => interopDefault(import('../pages/_slug/_params/index.vue' /* webpackChunkName: "pages/_slug/_params/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _0e433b93,
    name: "about___uk"
  }, {
    path: "/calculators",
    component: _49a49b0c,
    name: "calculators___uk"
  }, {
    path: "/cart",
    component: _e08117ec,
    name: "cart___uk"
  }, {
    path: "/checkout",
    component: _1cb34a30,
    name: "checkout___uk"
  }, {
    path: "/compare",
    component: _71dba56b,
    name: "compare___uk"
  }, {
    path: "/constructors",
    component: _4e57da66,
    name: "constructors___uk"
  }, {
    path: "/contacts",
    component: _12bb0ebd,
    name: "contacts___uk"
  }, {
    path: "/credit",
    component: _45dcc2e3,
    name: "credit___uk"
  }, {
    path: "/delivery",
    component: _23abdc44,
    name: "delivery___uk"
  }, {
    path: "/employment",
    component: _6a45d156,
    name: "employment___uk"
  }, {
    path: "/en",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/faq",
    component: _ca324bc8,
    name: "faq___uk"
  }, {
    path: "/forgot",
    component: _554ad60d,
    name: "forgot___uk"
  }, {
    path: "/installment",
    component: _6cc30cff,
    name: "installment___uk"
  }, {
    path: "/installments",
    component: _898eb0f2,
    name: "installments___uk"
  }, {
    path: "/legal",
    component: _49d7f682,
    name: "legal___uk"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login___uk"
  }, {
    path: "/news",
    component: _3b011b86,
    name: "news___uk"
  }, {
    path: "/payment",
    component: _c45620e2,
    name: "payment___uk"
  }, {
    path: "/privacy",
    component: _37e8fae4,
    name: "privacy___uk"
  }, {
    path: "/redirect",
    component: _039c8c74,
    name: "redirect___uk"
  }, {
    path: "/register",
    component: _5fba350d,
    name: "register___uk"
  }, {
    path: "/ru",
    component: _2dfb1658,
    name: "index___ru"
  }, {
    path: "/service",
    component: _177ec73b,
    name: "service___uk"
  }, {
    path: "/support",
    component: _1c176355,
    name: "support___uk"
  }, {
    path: "/terms",
    component: _500e56e6,
    name: "terms___uk"
  }, {
    path: "/thanks",
    component: _28e626b3,
    name: "thanks___uk"
  }, {
    path: "/tradein",
    component: _4c0ca95c,
    name: "tradein___uk"
  }, {
    path: "/user",
    component: _152321f8,
    name: "user___uk"
  }, {
    path: "/warranty",
    component: _76f032e6,
    name: "warranty___uk"
  }, {
    path: "/constructors/apparel",
    component: _669e2d66,
    name: "constructors-apparel___uk"
  }, {
    path: "/en/about",
    component: _0e433b93,
    name: "about___en"
  }, {
    path: "/en/calculators",
    component: _49a49b0c,
    name: "calculators___en"
  }, {
    path: "/en/cart",
    component: _e08117ec,
    name: "cart___en"
  }, {
    path: "/en/checkout",
    component: _1cb34a30,
    name: "checkout___en"
  }, {
    path: "/en/compare",
    component: _71dba56b,
    name: "compare___en"
  }, {
    path: "/en/constructors",
    component: _4e57da66,
    name: "constructors___en"
  }, {
    path: "/en/contacts",
    component: _12bb0ebd,
    name: "contacts___en"
  }, {
    path: "/en/credit",
    component: _45dcc2e3,
    name: "credit___en"
  }, {
    path: "/en/delivery",
    component: _23abdc44,
    name: "delivery___en"
  }, {
    path: "/en/employment",
    component: _6a45d156,
    name: "employment___en"
  }, {
    path: "/en/faq",
    component: _ca324bc8,
    name: "faq___en"
  }, {
    path: "/en/forgot",
    component: _554ad60d,
    name: "forgot___en"
  }, {
    path: "/en/installment",
    component: _6cc30cff,
    name: "installment___en"
  }, {
    path: "/en/installments",
    component: _898eb0f2,
    name: "installments___en"
  }, {
    path: "/en/legal",
    component: _49d7f682,
    name: "legal___en"
  }, {
    path: "/en/login",
    component: _10ba8d22,
    name: "login___en"
  }, {
    path: "/en/news",
    component: _3b011b86,
    name: "news___en"
  }, {
    path: "/en/payment",
    component: _c45620e2,
    name: "payment___en"
  }, {
    path: "/en/privacy",
    component: _37e8fae4,
    name: "privacy___en"
  }, {
    path: "/en/redirect",
    component: _039c8c74,
    name: "redirect___en"
  }, {
    path: "/en/register",
    component: _5fba350d,
    name: "register___en"
  }, {
    path: "/en/service",
    component: _177ec73b,
    name: "service___en"
  }, {
    path: "/en/support",
    component: _1c176355,
    name: "support___en"
  }, {
    path: "/en/terms",
    component: _500e56e6,
    name: "terms___en"
  }, {
    path: "/en/thanks",
    component: _28e626b3,
    name: "thanks___en"
  }, {
    path: "/en/tradein",
    component: _4c0ca95c,
    name: "tradein___en"
  }, {
    path: "/en/user",
    component: _152321f8,
    name: "user___en"
  }, {
    path: "/en/warranty",
    component: _76f032e6,
    name: "warranty___en"
  }, {
    path: "/ru/about",
    component: _0e433b93,
    name: "about___ru"
  }, {
    path: "/ru/calculators",
    component: _49a49b0c,
    name: "calculators___ru"
  }, {
    path: "/ru/cart",
    component: _e08117ec,
    name: "cart___ru"
  }, {
    path: "/ru/checkout",
    component: _1cb34a30,
    name: "checkout___ru"
  }, {
    path: "/ru/compare",
    component: _71dba56b,
    name: "compare___ru"
  }, {
    path: "/ru/constructors",
    component: _4e57da66,
    name: "constructors___ru"
  }, {
    path: "/ru/contacts",
    component: _12bb0ebd,
    name: "contacts___ru"
  }, {
    path: "/ru/credit",
    component: _45dcc2e3,
    name: "credit___ru"
  }, {
    path: "/ru/delivery",
    component: _23abdc44,
    name: "delivery___ru"
  }, {
    path: "/ru/employment",
    component: _6a45d156,
    name: "employment___ru"
  }, {
    path: "/ru/faq",
    component: _ca324bc8,
    name: "faq___ru"
  }, {
    path: "/ru/forgot",
    component: _554ad60d,
    name: "forgot___ru"
  }, {
    path: "/ru/installment",
    component: _6cc30cff,
    name: "installment___ru"
  }, {
    path: "/ru/installments",
    component: _898eb0f2,
    name: "installments___ru"
  }, {
    path: "/ru/legal",
    component: _49d7f682,
    name: "legal___ru"
  }, {
    path: "/ru/login",
    component: _10ba8d22,
    name: "login___ru"
  }, {
    path: "/ru/news",
    component: _3b011b86,
    name: "news___ru"
  }, {
    path: "/ru/payment",
    component: _c45620e2,
    name: "payment___ru"
  }, {
    path: "/ru/privacy",
    component: _37e8fae4,
    name: "privacy___ru"
  }, {
    path: "/ru/redirect",
    component: _039c8c74,
    name: "redirect___ru"
  }, {
    path: "/ru/register",
    component: _5fba350d,
    name: "register___ru"
  }, {
    path: "/ru/service",
    component: _177ec73b,
    name: "service___ru"
  }, {
    path: "/ru/support",
    component: _1c176355,
    name: "support___ru"
  }, {
    path: "/ru/terms",
    component: _500e56e6,
    name: "terms___ru"
  }, {
    path: "/ru/thanks",
    component: _28e626b3,
    name: "thanks___ru"
  }, {
    path: "/ru/tradein",
    component: _4c0ca95c,
    name: "tradein___ru"
  }, {
    path: "/ru/user",
    component: _152321f8,
    name: "user___ru"
  }, {
    path: "/ru/warranty",
    component: _76f032e6,
    name: "warranty___ru"
  }, {
    path: "/tradein/checkout",
    component: _4baddaf6,
    name: "tradein-checkout___uk"
  }, {
    path: "/tradein/thanks",
    component: _068b9e0e,
    name: "tradein-thanks___uk"
  }, {
    path: "/user/addresses",
    component: _1403264b,
    name: "user-addresses___uk"
  }, {
    path: "/user/orders",
    component: _0b0db472,
    name: "user-orders___uk"
  }, {
    path: "/user/password",
    component: _571c5536,
    name: "user-password___uk"
  }, {
    path: "/user/preferences",
    component: _83ee1ac4,
    name: "user-preferences___uk"
  }, {
    path: "/user/wishlist",
    component: _bdf3a8e2,
    name: "user-wishlist___uk"
  }, {
    path: "/en/constructors/apparel",
    component: _669e2d66,
    name: "constructors-apparel___en"
  }, {
    path: "/en/tradein/checkout",
    component: _4baddaf6,
    name: "tradein-checkout___en"
  }, {
    path: "/en/tradein/thanks",
    component: _068b9e0e,
    name: "tradein-thanks___en"
  }, {
    path: "/en/user/addresses",
    component: _1403264b,
    name: "user-addresses___en"
  }, {
    path: "/en/user/orders",
    component: _0b0db472,
    name: "user-orders___en"
  }, {
    path: "/en/user/password",
    component: _571c5536,
    name: "user-password___en"
  }, {
    path: "/en/user/preferences",
    component: _83ee1ac4,
    name: "user-preferences___en"
  }, {
    path: "/en/user/wishlist",
    component: _bdf3a8e2,
    name: "user-wishlist___en"
  }, {
    path: "/installments/privatbank/cancel",
    component: _14c97c00,
    name: "installments-privatbank-cancel___uk"
  }, {
    path: "/installments/privatbank/success",
    component: _cdca6226,
    name: "installments-privatbank-success___uk"
  }, {
    path: "/payment/liqpay/cancel",
    component: _3b6db8dc,
    name: "payment-liqpay-cancel___uk"
  }, {
    path: "/payment/liqpay/success",
    component: _7b90199b,
    name: "payment-liqpay-success___uk"
  }, {
    path: "/payment/paypal/cancel",
    component: _f990ce5a,
    name: "payment-paypal-cancel___uk"
  }, {
    path: "/payment/paypal/success",
    component: _0f1f670c,
    name: "payment-paypal-success___uk"
  }, {
    path: "/ru/constructors/apparel",
    component: _669e2d66,
    name: "constructors-apparel___ru"
  }, {
    path: "/ru/tradein/checkout",
    component: _4baddaf6,
    name: "tradein-checkout___ru"
  }, {
    path: "/ru/tradein/thanks",
    component: _068b9e0e,
    name: "tradein-thanks___ru"
  }, {
    path: "/ru/user/addresses",
    component: _1403264b,
    name: "user-addresses___ru"
  }, {
    path: "/ru/user/orders",
    component: _0b0db472,
    name: "user-orders___ru"
  }, {
    path: "/ru/user/password",
    component: _571c5536,
    name: "user-password___ru"
  }, {
    path: "/ru/user/preferences",
    component: _83ee1ac4,
    name: "user-preferences___ru"
  }, {
    path: "/ru/user/wishlist",
    component: _bdf3a8e2,
    name: "user-wishlist___ru"
  }, {
    path: "/en/installments/privatbank/cancel",
    component: _14c97c00,
    name: "installments-privatbank-cancel___en"
  }, {
    path: "/en/installments/privatbank/success",
    component: _cdca6226,
    name: "installments-privatbank-success___en"
  }, {
    path: "/en/payment/liqpay/cancel",
    component: _3b6db8dc,
    name: "payment-liqpay-cancel___en"
  }, {
    path: "/en/payment/liqpay/success",
    component: _7b90199b,
    name: "payment-liqpay-success___en"
  }, {
    path: "/en/payment/paypal/cancel",
    component: _f990ce5a,
    name: "payment-paypal-cancel___en"
  }, {
    path: "/en/payment/paypal/success",
    component: _0f1f670c,
    name: "payment-paypal-success___en"
  }, {
    path: "/ru/installments/privatbank/cancel",
    component: _14c97c00,
    name: "installments-privatbank-cancel___ru"
  }, {
    path: "/ru/installments/privatbank/success",
    component: _cdca6226,
    name: "installments-privatbank-success___ru"
  }, {
    path: "/ru/payment/liqpay/cancel",
    component: _3b6db8dc,
    name: "payment-liqpay-cancel___ru"
  }, {
    path: "/ru/payment/liqpay/success",
    component: _7b90199b,
    name: "payment-liqpay-success___ru"
  }, {
    path: "/ru/payment/paypal/cancel",
    component: _f990ce5a,
    name: "payment-paypal-cancel___ru"
  }, {
    path: "/ru/payment/paypal/success",
    component: _0f1f670c,
    name: "payment-paypal-success___ru"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___uk"
  }, {
    path: "/en/user/addresses/:address",
    component: _5290f74c,
    name: "user-addresses-address___en"
  }, {
    path: "/en/user/orders/:order",
    component: _86aae742,
    name: "user-orders-order___en"
  }, {
    path: "/ru/user/addresses/:address",
    component: _5290f74c,
    name: "user-addresses-address___ru"
  }, {
    path: "/ru/user/orders/:order",
    component: _86aae742,
    name: "user-orders-order___ru"
  }, {
    path: "/en/search/:params?",
    component: _8fca26b0,
    name: "search-params___en"
  }, {
    path: "/ru/search/:params?",
    component: _8fca26b0,
    name: "search-params___ru"
  }, {
    path: "/user/addresses/:address",
    component: _5290f74c,
    name: "user-addresses-address___uk"
  }, {
    path: "/user/orders/:order",
    component: _86aae742,
    name: "user-orders-order___uk"
  }, {
    path: "/en/auth/:driver?/callback",
    component: _a7b013cc,
    name: "auth-driver-callback___en"
  }, {
    path: "/en/installments/:company/cancel",
    component: _92ba9740,
    name: "installments-company-cancel___en"
  }, {
    path: "/en/installments/:company/progress",
    component: _6eef5b13,
    name: "installments-company-progress___en"
  }, {
    path: "/en/installments/:company/success",
    component: _3268a28d,
    name: "installments-company-success___en"
  }, {
    path: "/ru/auth/:driver?/callback",
    component: _a7b013cc,
    name: "auth-driver-callback___ru"
  }, {
    path: "/ru/installments/:company/cancel",
    component: _92ba9740,
    name: "installments-company-cancel___ru"
  }, {
    path: "/ru/installments/:company/progress",
    component: _6eef5b13,
    name: "installments-company-progress___ru"
  }, {
    path: "/ru/installments/:company/success",
    component: _3268a28d,
    name: "installments-company-success___ru"
  }, {
    path: "/en/:slug",
    component: _f6ee20da,
    name: "slug___en"
  }, {
    path: "/ru/:slug",
    component: _f6ee20da,
    name: "slug___ru"
  }, {
    path: "/search/:params?",
    component: _8fca26b0,
    name: "search-params___uk"
  }, {
    path: "/auth/:driver?/callback",
    component: _a7b013cc,
    name: "auth-driver-callback___uk"
  }, {
    path: "/installments/:company/cancel",
    component: _92ba9740,
    name: "installments-company-cancel___uk"
  }, {
    path: "/installments/:company/progress",
    component: _6eef5b13,
    name: "installments-company-progress___uk"
  }, {
    path: "/installments/:company/success",
    component: _3268a28d,
    name: "installments-company-success___uk"
  }, {
    path: "/en/:slug/:params",
    component: _70b7b0ae,
    name: "slug-params___en"
  }, {
    path: "/ru/:slug/:params",
    component: _70b7b0ae,
    name: "slug-params___ru"
  }, {
    path: "/en/:lang/*",
    component: _039c8c74,
    name: "custom___en"
  }, {
    path: "/ru/:lang/*",
    component: _039c8c74,
    name: "custom___ru"
  }, {
    path: "/:slug",
    component: _f6ee20da,
    name: "slug___uk"
  }, {
    path: "/:slug/:params",
    component: _70b7b0ae,
    name: "slug-params___uk"
  }, {
    path: "/:lang/*",
    component: _039c8c74,
    name: "custom___uk"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
