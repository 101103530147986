
export default {
  props: {
    value: {
      required: true,
      default () {
        return {}
      },
      type: null
    }
  },

  data () {
    return {
      chosenFilters: this.value
    }
  },

  watch: {
    value: {
      handler (val) {
        this.chosenFilters = val
      },
      immediate: true
    }
  }
}
