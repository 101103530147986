export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"name":"Русский","code":"ru","iso":"ru-UA","file":"ru.js","firstDayOfWeek":1,"weekdays":[1,2,3,4,5,6,0]},{"name":"Українська","code":"uk","iso":"uk-UA","file":"uk.js","firstDayOfWeek":1,"weekdays":[1,2,3,4,5,6,0]},{"name":"English","code":"en","iso":"en-UA","file":"en.js","firstDayOfWeek":0,"weekdays":[1,2,3,4,5,6,0]}],
  defaultLocale: "uk",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/app/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "https://yaknoviy.ua",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: true,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"name":"Русский","code":"ru","iso":"ru-UA","file":"ru.js","firstDayOfWeek":1,"weekdays":[1,2,3,4,5,6,0]},{"name":"Українська","code":"uk","iso":"uk-UA","file":"uk.js","firstDayOfWeek":1,"weekdays":[1,2,3,4,5,6,0]},{"name":"English","code":"en","iso":"en-UA","file":"en.js","firstDayOfWeek":0,"weekdays":[1,2,3,4,5,6,0]}],
  localeCodes: ["ru","uk","en"],
}

export const localeMessages = {
  'ru.js': () => import('../../lang/ru.js' /* webpackChunkName: "lang-ru.js" */),
  'uk.js': () => import('../../lang/uk.js' /* webpackChunkName: "lang-uk.js" */),
  'en.js': () => import('../../lang/en.js' /* webpackChunkName: "lang-en.js" */),
}
