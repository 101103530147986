
export default {
  model: {
    prop: 'active',
    event: 'update:active'
  },

  props: {
    active: Boolean,
    programmatic: Boolean,
    animation: {
      type: String,
      default: 'fade'
    }
  },

  data () {
    return {
      isActive: this.active || false
    }
  },

  watch: {
    active (value) {
      this.isActive = value
    }
  },

  beforeMount () {
    if (this.programmatic) {
      document.body.appendChild(this.$el)
    }
  },

  mounted () {
    if (this.programmatic) {
      this.isActive = true
    }
  }
}
