
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from '@vue/composition-api'

export default {
  components: {
    RemoveCartModal: defineAsyncComponent(() => import('@/components/modals/RemoveCartModal'))
  },

  props: {
    item: {
      required: true,
      type: Object
    }
  },

  data: () => ({
    removeModal: false,
    loading: {
      subtract: false,
      add: false
    }
  }),

  computed: {
    ...mapGetters({
      currency: 'currency'
    }),

    price () {
      return this.item.store.price.current + this.item.warranties.reduce((total, item) => total + item.price, 0) + this.item.addons.reduce((total, item) => total + item.price, 0)
    },

    total () {
      return this.item.quantity * this.price
    },

    discount () {
      if (this.item.coupon === null) {
        return 0
      }

      if (this.item.coupon.discount.type.type === 'percent') {
        return Math.round(this.price * this.item.coupon.discount.percent / 100)
      } else {
        return this.item.coupon.discount.amount.amount
      }
    },

    totalDiscount () {
      return this.item.quantity * this.discount
    },

    totalWithDiscount () {
      return this.total - this.discount
    }
  },

  methods: {
    openConditionStatusInfoModal (conditionStatus) {
      this.$store.dispatch('modal/setInfoModalTitle', conditionStatus.title)
      this.$store.dispatch('modal/setInfoModalDescription', conditionStatus.description)
      this.$store.dispatch('modal/setInfoModalActive', true)
    },

    async subtractQuantity (item) {
      if (item.quantity <= 1) {
        return
      }

      this.loading.subtract = true

      await this.$store.dispatch('cart/subtractQuantity', item)

      this.$emit('update:quantity', item.quantity - 1)

      this.loading.subtract = false
    },

    async addQuantity (item) {
      this.loading.add = true

      await this.$store.dispatch('cart/addQuantity', item)

      this.$emit('update:quantity', item)

      this.loading.add = false
    }
  }
}
