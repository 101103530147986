
export default {
  props: {
    contact: {
      type: Object,
      required: true
    },
    iconClass: {
      type: String,
      default: '',
      required: false
    }
  }
}
