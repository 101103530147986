
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from '@vue/composition-api'

import ProductPartialsStockStatusTag from '@/components/product/partials/StockStatusTag'
import ProductCard from '@/components/product/Card'
import ProductTabsDescription from '@/components/product/tabs/Description'
import ProductTabsCharacteristics from '@/components/product/tabs/Characteristics'
import ProductTabsStores from '@/components/product/tabs/Stores'
import ProductTabsGallery from '@/components/product/tabs/Gallery'
import ProductTabsReviews from '@/components/product/tabs/Reviews'
import ProductTabsQuestions from '@/components/product/tabs/Questions'

export default {
  components: {
    ProductPartialsStockStatusTag,
    ProductCard,
    ProductTabsDescription,
    ProductTabsCharacteristics,
    ProductTabsStores,
    ProductTabsGallery,
    ProductTabsReviews,
    ProductTabsQuestions,
    LazyProductPartialsRecent: defineAsyncComponent(() => import('@/components/product/partials/Recent')),
    LazyProductPartialsSimilar: defineAsyncComponent(() => import('@/components/product/partials/Similar')),
    LazyProductPartialsRelated: defineAsyncComponent(() => import('@/components/product/partials/Related')),
    LazyProductPartialsDescription: defineAsyncComponent(() => import('@/components/product/partials/Description'))
  },

  props: {
    product: {
      type: Object,
      default () {
        return {}
      },
      required: true
    }
  },

  data () {
    return {
      breadcrumbCharacteristicsRoles: [
        // 'brand',
        // 'artist',
        'series'
      ],
      tabs: {
        current: '',
        all: [
          {
            component: 'product-tabs-description',
            hash: 'description',
            name: 'description'
          },
          {
            component: 'product-tabs-characteristics',
            hash: 'characteristics',
            name: 'characteristics.title'
          },
          {
            component: 'product-tabs-stores',
            hash: 'stores',
            name: 'stores.title'
          },
          {
            component: 'product-tabs-gallery',
            hash: 'gallery',
            name: 'gallery'
          },
          {
            component: 'product-tabs-reviews',
            hash: 'reviews',
            name: 'reviews.title'
          },
          {
            component: 'product-tabs-questions',
            hash: 'questions',
            name: 'questions.title'
          }
        ]
      },
      warranty: {
        types: []
      },
      addon: {
        types: []
      }
    }
  },

  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      cartModal: 'cart/modal',
      currency: 'currency',
      country: 'country',
      sticky: 'sticky',
      support: 'support/support'
    }),

    breadcrumbs () {
      this.product.breadcrumbs.map((crumb, index) => {
        crumb.to = this.localePath({ name: 'slug', params: { slug: crumb.slug } })
        crumb.active = false
      })

      const breadcrumbs = [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        }
      ].concat(this.product.breadcrumbs)

      this.breadcrumbCharacteristicsRoles.forEach((role) => {
        this.flatCharacteristics.filter(characteristic => characteristic.role === role).forEach((characteristic) => {
          characteristic.values.forEach((value) => {
            breadcrumbs.push({
              title: value.title,
              to: this.localePath({
                name: 'slug-params',
                params: {
                  slug: this.product.categories[0].page.slug,
                  params: `${characteristic.slug}--${value.filter_value.slug}`
                }
              }),
              active: false
            })
          })
        })
      })

      return breadcrumbs
    },

    flatCharacteristics () {
      return this.product.characteristics.map(group => group.characteristics).flat()
    },

    artistCharacteristics () {
      return this.flatCharacteristics.filter(characteristic => characteristic.role === 'artist')
    },

    brandCharacteristics () {
      return this.flatCharacteristics.filter(characteristic => characteristic.role === 'brand')
    },

    addOnPrice () {
      let addonsPrice = 0
      let warrantiesPrice = 0

      this.addon.types.forEach((type) => {
        addonsPrice += (Object.keys(type).length ? type.price : 0)
      })

      this.warranty.types.forEach((type) => {
        warrantiesPrice += (Object.keys(type).length ? type.price : 0)
      })

      return warrantiesPrice + addonsPrice
    },

    oldPrice () {
      if (this.product.store.price.old) {
        return this.product.store.price.old + this.addOnPrice
      }

      return 0
    },

    price () {
      return this.product.store.price.current + this.addOnPrice
    },

    splitPrice () {
      return this.price.toString().split('.')
    },

    discountPercent () {
      return this.oldPrice ? Math.ceil((this.oldPrice - this.price) / this.oldPrice * 100) : 0
    },

    discount () {
      return this.oldPrice ? this.oldPrice - this.price : 0
    },

    conditionRating () {
      if (this.product.store.conditions.length > 0) {
        return Math.round(this.product.store.conditions.reduce((total, next) => total + next.option.stars, 0) / this.product.store.conditions.length * 10) / 10
      } else {
        return this.product.store.condition
      }
    }
  },

  watch: {
    'tabs.current': {
      handler (val) {
        if (['stores', 'reviews', 'questions'].includes(val)) {
          window.scrollTo(0, 0)
        }

        if (this.$refs.allTabs && this.$refs.allTabs.scrollWidth > window.innerWidth) {
          let left = 0

          const tabs = this.tabs.all.filter((value, index, array) => array.findIndex(a => a.hash === val) > index)

          tabs.forEach((t) => {
            left += `${t.hash}Tab` in this.$refs ? this.$refs[`${t.hash}Tab`][0].scrollWidth : 0
          })

          left -= (window.innerWidth - this.$refs[`${val}Tab`][0].scrollWidth - 80) / 2

          this.$nextTick(() => this.$refs.allTabs.scrollTo({ left, behavior: 'smooth' }))
        }
      },
      immediate: true,
      deep: true
    }
  },

  created () {
    this.$store.commit('SET_LOCAL_DELIVERY_OPTIONS', {})
    this.$store.commit('SET_LOCAL_PAYMENT_OPTIONS', {})

    this.tabs.current = this.tabs.all.map(tab => tab.hash).includes(this.$route.query.tab)
      ? this.$route.query.tab
      : this.tabs.all[0].hash

    this.product.question = {
      question: ''
    }

    this.setProductFixedPriceButton()
  },

  mounted () {
    this.$gtm.push({
      event: 'view_item',
      ecommerce: {
        items: [
          {
            item_name: this.product.title,
            item_id: this.product.store.id,
            price: this.product.store.price.current.toFixed(2),
            item_brand: this.brandCharacteristics.length > 0 && this.brandCharacteristics[0].values.length > 0
              ? this.brandCharacteristics[0].values[0].title
              : '',
            item_category: this.product.breadcrumbs.length ? this.product.breadcrumbs[0].title : '',
            item_category2: this.product.breadcrumbs.length ? this.product.breadcrumbs[this.product.breadcrumbs.length - 1].title : ''
          }
        ]
      }
    })
  },

  methods: {
    togglePreorderModal () {
      this.$store.dispatch('modal/setPreorderId', this.product.id)
      this.$store.dispatch('modal/setPreorderTitle', this.product.title)
      this.$store.dispatch('modal/setPreorderImage', {
        src: `${this.product.images[0].folder}/card/src/${this.product.images[0].url}`,
        lazy: `${this.product.images[0].folder}/card/lazy/${this.product.images[0].url}`,
        alt: this.product.images[0].alt
      })
      this.$store.dispatch('modal/togglePreorderModal', true)
    },

    toggleNotifyAboutAvailabilityModal () {
      this.$store.dispatch('modal/setNotifyAboutProductAvailabilityId', this.product.id)
      this.$store.dispatch('modal/setNotifyAboutProductAvailabilityTitle', this.product.title)
      this.$store.dispatch('modal/setNotifyAboutProductAvailabilityImage', {
        src: `${this.product.images[0].folder}/card/src/${this.product.images[0].url}`,
        lazy: `${this.product.images[0].folder}/card/lazy/${this.product.images[0].url}`,
        alt: this.product.images[0].alt
      })
      this.$store.dispatch('modal/toggleNotifyAboutProductAvailabilityModal', true)
    },

    toggleTab (tab) {
      this.tabs.current = tab

      history.replaceState({}, null, `${window.location.origin}${window.location.pathname}?tab=${tab}`)

      this.setProductFixedPriceButton()
    },

    warrantySelected (types) {
      this.warranty.types = types
    },

    addonSelected (types) {
      this.addon.types = types
    },

    setProductFixedPriceButton () {
      this.$store.dispatch('support/setProductFixedPriceButton', this.product.stores_count === 1 && !['stores'].includes(this.tabs.current))
    },

    openConditionStatusInfoModal (conditionStatus) {
      this.$store.dispatch('modal/setInfoModalTitle', conditionStatus.title)
      this.$store.dispatch('modal/setInfoModalDescription', conditionStatus.description)
      this.$store.dispatch('modal/setInfoModalActive', true)
    },

    addToCart (item, itemStore, warranties, addons) {
      this.$store.dispatch('cart/setCartType', 'cart')
      this.$store.dispatch('cart/addToCart', { item, itemStore, warranties, addons })
      this.$store.dispatch('cart/setCartModalActive', !this.cartModal.active)
    },

    scrollTabLeft () {
      this.$refs.allTabs.scrollLeft -= 130
    },

    scrollTabRight () {
      this.$refs.allTabs.scrollLeft += 130
    }
  },
  head () {
    return {
      meta: [
        {
          hid: 'og:image',
          property: 'og:image',
          content: `${this.product.images[0].folder}/page/lazy/${this.product.images[0].url}`
        }
      ]
    }
  }
}
