
import { mapGetters } from 'vuex'

export default {
  props: {
    item: {
      required: true,
      type: Object
    }
  },

  computed: {
    ...mapGetters({
      cart: 'cart/cart'
    })
  },

  methods: {
    async remove () {
      await this.$store.dispatch('cart/removeFromCart', this.item)

      if (this.cart.items.length === 0) {
        this.$store.dispatch('cart/setCartModalActive', false)
      }

      this.$emit('toggle')
    }
  }
}
