export default {
  data: () => ({
    light: 'hsl(221, 14%, 96%)',
    dark: 'hsl(221, 14%, 14%)'
  }),

  computed: {
    themeColor () {
      if (typeof window !== 'undefined') {
        return this.getPreferredColorScheme()
      }

      return this.light
    }
  },

  mounted () {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.setTheme)
  },

  beforeDestroy () {
    window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', this.setTheme)
  },

  methods: {
    setTheme (e) {
      document.querySelector("meta[name='theme-color']").content = this.getPreferredColorScheme()
    },

    getPreferredColorScheme () {
      return window.matchMedia('(prefers-color-scheme: dark)').matches ? this.dark : this.light
    },

    getContrastColor (color) {
      let r, g, b

      if (color.startsWith('#')) {
        const hex = color.replace('#', '')
        if (hex.length === 3) {
          r = parseInt(hex[0] + hex[0], 16)
          g = parseInt(hex[1] + hex[1], 16)
          b = parseInt(hex[2] + hex[2], 16)
        } else if (hex.length === 6) {
          r = parseInt(hex.substring(0, 2), 16)
          g = parseInt(hex.substring(2, 4), 16)
          b = parseInt(hex.substring(4, 6), 16)
        }
      } else if (color.startsWith('rgb')) {
        const match = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)/)

        if (match) {
          r = parseInt(match[1])
          g = parseInt(match[2])
          b = parseInt(match[3])
        }
      } else if (color.startsWith('hsl')) {
        const match = color.match(/hsla?\((\d+),\s*(\d+)%,\s*(\d+)%/)

        if (match) {
          const h = parseInt(match[1]) / 360
          const s = parseInt(match[2]) / 100
          const l = parseInt(match[3]) / 100

          if (s === 0) {
            r = g = b = Math.round(l * 255)
          } else {
            const q = l < 0.5 ? l * (1 + s) : l + s - l * s
            const p = 2 * l - q

            r = Math.round(this.hueToRgb(p, q, h + 1 / 3) * 255)
            g = Math.round(this.hueToRgb(p, q, h) * 255)
            b = Math.round(this.hueToRgb(p, q, h - 1 / 3) * 255)
          }
        }
      }

      if (r !== undefined && g !== undefined && b !== undefined) {
        const brightness = (r * 299 + g * 587 + b * 114) / 1000
        return brightness < 128 ? this.light : this.dark
      }

      return this.dark
    },

    hueToRgb (p, q, t) {
      if (t < 0) {
        t += 1
      }

      if (t > 1) {
        t -= 1
      }

      if (t < 1 / 6) {
        return p + (q - p) * 6 * t
      }

      if (t < 1 / 2) {
        return q
      }

      if (t < 2 / 3) {
        return p + (q - p) * (2 / 3 - t) * 6
      }

      return p
    },

    getColorStyles (color) {
      if (color === null || typeof color === 'undefined') {
        return {
          color: 'inherit',
          backgroundColor: 'transparent'
        }
      }

      const value = this.themeColor === this.dark ? color.contrast : color.value

      if (value === 'transparent') {
        return {
          color: 'inherit',
          backgroundColor: 'transparent'
        }
      } else {
        return {
          color: this.getContrastColor(value),
          backgroundColor: value
        }
      }
    }
  }
}
