
import { mapGetters } from 'vuex'

import ProductCard from '@/components/product/Card'

export default {
  components: {
    ProductCard
  },

  data () {
    return {
      options: {
        slidesPerView: 'auto',
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          watchOverflow: true,
          clickable: true
        },
        spaceBetween: 0,
        // simulateTouch: false,
        firstSlideReached: true,
        lastSlideReached: false,
        // allowTouchMove: false,
        on: {
          slideChange: () => {
            this.edgeReached()
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      recent: 'recent/recent'
    }),

    visibleSlidesPerView () {
      if (['mobileSmall', 'mobile'].includes(this.$mq)) {
        return 2
      } else if (['tablet'].includes(this.$mq)) {
        return 3
      } else if (['desktop'].includes(this.$mq)) {
        return 4
      } else if (['widescreen'].includes(this.$mq)) {
        return 5
      } else {
        return 6
      }
    },

    hideButtons () {
      if (['mobileSmall', 'mobile'].includes(this.$mq) && this.recent.items.length <= 2) {
        return true
      } else if (['tablet'].includes(this.$mq) && this.recent.items.length <= 3) {
        return true
      } else if (['desktop'].includes(this.$mq) && this.recent.items.length <= 4) {
        return true
      } else if (['widescreen'].includes(this.$mq) && this.recent.items.length <= 5) {
        return true
      } else if (['fullhd'].includes(this.$mq) && this.recent.items.length <= 6) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    slide (index) {
      this.swiper.slideTo(index, 1000, false)

      this.edgeReached()
    },

    edgeReached () {
      this.options.firstSlideReached = this.swiper.snapIndex === 0

      this.options.lastSlideReached = this.swiper.slides.length - this.swiper.snapIndex - this.visibleSlidesPerView === 0
    }
  }
}
